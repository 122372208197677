import React, { useState } from "react";
import { Button, Steps, theme } from "antd";
import Title from "../../../components/ResourcePacker/Steps/PackInformation";
import PackagerStep from "../../../components/PackagerStep";
import { useDispatch } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Screenshots from "../../../components/ResourcePacker/Steps/Screenshots";
import PartnerArt from "../../../components/Common/PartnerArt";
import Assets from "../../../components/ResourcePacker/Steps/Assets";
import UploadResource from "../../../components/ResourcePacker/Steps/UploadResource";
import useResourcePack from "../../../hooks/useResourcePack";
import {
  checkResourcePack,
  newResourcePack,
} from "../../../utils/resourcePacker";

export default function ResourcePacker() {
  const { resourcePack: pack, setResourcePack } = useResourcePack();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = [
    {
      title: "Pack information",
      content: <Title />,
    },
    {
      title: "Partner art",
      content: <PartnerArt packType="resourcePack" />,
    },
    {
      title: "Screenshots",
      content: <Screenshots />,
    },
    {
      title: "Other assets",
      content: <Assets />,
    },
    {
      title: "Upload resource",
      content: <UploadResource />,
    },
  ];

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 24,
    maxHeight: 710,
  };

  const onChange = (value: number) => {
    setCurrent(value);
  };

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Resource Packer</title>
      </Helmet>
      <Steps current={current} items={items} onChange={onChange} />
      <div style={contentStyle}>
        <PackagerStep title={steps[current].title}>
          {steps[current].content}
        </PackagerStep>
      </div>
      <div
        style={{ marginTop: 12 }}
        className="container full-width justify-end"
      >
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            size="large"
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current === 0 && (
          <Button
            type="dashed"
            size="large"
            style={{ margin: "0 8px" }}
            danger
            onClick={() => setResourcePack(newResourcePack())}
          >
            New pack
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" size="large" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            size="large"
            type="primary"
            onClick={() =>
              // cambiar a world pack
              checkResourcePack(pack, dispatch)
                ? navigate("/packer/resource/success")
                : navigate("/packer/resource/error")
            }
            icon={<CheckOutlined />}
          >
            Finish
          </Button>
        )}
      </div>
    </>
  );
}
