import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../firebase/firebase";
import { currentUser } from "../firebase/firestore";
import { createUserObject } from "../utils/user";
import Login from "./Login";
import { Spin } from "antd";
import useUser from "../hooks/useUser";

interface Props {
  children: JSX.Element;
}

export default function AuthControl({ children }: Props) {
  const dispatch = useDispatch();
  const { currentUser: loggedUser } = useUser();
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAuth(true);
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        setLoadingAuth(false);
        return;
      }
      // if (!(user.email?.split("@")[1] === "555comic.com")) {
      //   message.error("Please sign in with your company account", 5);
      //   signOut(auth);
      //   setLoadingAuth(false);
      //   return;
      // }
      currentUser(createUserObject(user), dispatch);
      if (loggedUser.uid !== "") setLoadingAuth(false);
    });
  }, []);

  return (
    <>
      {loggedUser.uid !== "" ? (
        children
      ) : !loadingAuth ? (
        <Login loading={loadingAuth} />
      ) : (
        <div className="fixed-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
