import { useAppDispatch, useAppSelector } from "./store";
import { SkinPackFB } from "../interfaces/skinPacker";
import { setSkinPacksState } from "../store/slices/skinPacksSlice";

export default function useSkinPackCollection() {
  const skinPacks = useAppSelector((state) => state.skinPackCollections);
  const dispatch = useAppDispatch();

  const setSkinPacks = (packs: SkinPackFB[]) => {
    dispatch(setSkinPacksState(packs));
  };

  return {
    skinPacks,
    setSkinPacks,
  };
}
