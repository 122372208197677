import React, { useState } from "react";
import { Button, DatePicker, Popconfirm, Select, Tooltip, message } from "antd";
import { useDispatch } from "react-redux";
import { deleteSkinPack } from "../../../firebase/firestore";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { SkinPackFB } from "../../../interfaces/skinPacker";
import { useNavigate } from "react-router-dom";
import {
  createSkinPackZip,
  updatePackSchedule,
  updatePackStatus,
} from "../../../utils/skinPacker";
import STATUS from "../../../utils/status";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import useSkinPackCollection from "../../../hooks/useSkinPackCollection";
import useUser from "../../../hooks/useUser";
import useSkinPack from "../../../hooks/useSkinPack";

interface Props {
  pack: SkinPackFB;
}

export default function ActionButtons({ pack }: Props) {
  const { skinPacks } = useSkinPackCollection();
  const { setSkinPack } = useSkinPack();
  const { currentUser } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  async function handleScheduleChange(value: Dayjs | null) {
    await updatePackSchedule(
      pack,
      skinPacks,
      value?.format("YYYY-M-D") ?? "",
      currentUser,
      dispatch
    ).then((result) => {
      if (result) {
        message.success("Pack scheduled.", 5);
      } else {
        message.error("An error occured.", 5);
      }
    });
  }
  return (
    <div
      className="container justify-end align-center gap-8 wrap"
      style={{ flex: 2 }}
    >
      <div>
        <Select
          options={Object.keys(STATUS).map((status) => ({
            value: status,
            label: STATUS[status].label,
          }))}
          value={pack.status}
          style={{ width: "100%" }}
          placeholder="Add tags"
          onChange={async (value) =>
            await updatePackStatus(
              pack,
              skinPacks,
              value,
              currentUser,
              dispatch
            ).then((result) => {
              if (result) {
                message.success("Status updated.", 5);
              } else {
                message.error("An error occured.", 5);
              }
            })
          }
        />
      </div>
      {pack.status === "scheduled" && (
        <DatePicker
          disabledDate={(date) => date.day() !== 2}
          defaultValue={pack.schedule === "" ? undefined : dayjs(pack.schedule)}
          placeholder="Release date"
          status={pack.schedule === "" ? "warning" : ""}
          onChange={(value) => {
            handleScheduleChange(value);
          }}
        />
      )}
      <Tooltip title="Edit">
        <Button
          type="dashed"
          icon={<EditOutlined />}
          onClick={() => {
            setSkinPack(pack.pack);
            navigate("/packer/skins");
          }}
        />
      </Tooltip>
      <Tooltip title="Download">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={async () => {
            setLoading(true);
            await createSkinPackZip(pack.pack).then(() => setLoading(false));
          }}
          loading={loading}
        />
      </Tooltip>
      <Popconfirm
        title="Delete pack"
        description="Are you sure you want to delete this pack?"
        okText="Yes, delete"
        okType="danger"
        icon={<DeleteOutlined style={{ color: "#cc0000" }} />}
        onConfirm={async () => {
          await deleteSkinPack(pack.pack, currentUser);
          navigate("/packs");
        }}
      >
        <Tooltip title="Delete">
          <Button icon={<DeleteOutlined />} danger type="primary" />
        </Tooltip>
      </Popconfirm>
    </div>
  );
}
