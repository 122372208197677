import { SkinPack } from "../interfaces/skinPacker";

const packObject: SkinPack = {
  title: "",
  uuid: "",
  uuidModules: "",
  version: [1, 0, 0],
  skins: [],
  keyart: {
    uid: "",
    name: "",
    url: "",
  },
  partnerArt: {
    uid: "",
    name: "",
    url: "",
  },
};

export default packObject;
