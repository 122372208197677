import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { TPCreator, TPSlot } from "../../interfaces/texturePackCreator";

type SetSlotPayload =
  | {
      slot: number;
      slotValue: TPSlot;
      packID: string;
      action: "delete";
    }
  | {
      slot: number;
      slotValue: TPSlot;
      packID?: never;
      action: "set";
    };

const initialState: TPCreator = {
  slots: {},
  amountSlots: 3,
};

export const TPCreatorSlice = createSlice({
  name: "TPCreator",
  initialState,
  reducers: {
    setSlotsState: (state, action: PayloadAction<TPCreator>) => {
      return action.payload;
    },
    setSlot: (state, action: PayloadAction<SetSlotPayload>) => {
      const { slot, slotValue } = action.payload;
      state.slots[slot] = slotValue;
    },
    addTextureSlot: (
      state,
      action: PayloadAction<{
        slot: string;
        blockID: string;
        textureURL: string;
      }>
    ) => {
      const { slot, blockID, textureURL } = action.payload;
      const TPSlot = state.slots[slot + 1];
      TPSlot.textures[blockID] = textureURL;
    },
    addTexturePackID: (
      state,
      action: PayloadAction<{
        packID: string;
        blockID: string;
        textureURL: string;
      }>
    ) => {
      const { packID, blockID, textureURL } = action.payload;
      const TPSlotKey = Object.entries(state.slots).find(
        ([key, value]) => value.packID === packID
      )?.[0];
      if (TPSlotKey) {
        const TPSlot = state.slots[TPSlotKey];
        TPSlot.textures[blockID] = textureURL;
      }
    },
    removeTexturePackID: (
      state,
      action: PayloadAction<{
        packID: string;
        blockID: string;
      }>
    ) => {
      const { packID, blockID } = action.payload;
      const TPSlotKey = Object.entries(state.slots).find(
        ([key, value]) => value.packID === packID
      )?.[0];
      if (TPSlotKey) {
        const TPSlot = state.slots[TPSlotKey];
        delete TPSlot.textures[blockID];
      }
    },
  },
});

export default TPCreatorSlice.reducer;

export const {
  addTextureSlot,
  addTexturePackID,
  setSlotsState,
  setSlot,
  removeTexturePackID,
} = TPCreatorSlice.actions;
