import { useEffect, useState } from "react";
import { Alert, Carousel, Tag, message } from "antd";
import { ScreenshotFile } from "../../../interfaces/worldPacker";
import { v4 as uuidv4 } from "uuid";
import {
  fileToScreenshot,
  removeScreenshot,
  screenshotToFile,
  updateWorldPack,
} from "../../../utils/worldPacker";
import { useDispatch } from "react-redux";
import UploadImage from "../../Common/UploadImage";
import useWorldPack from "../../../hooks/useWorldPack";

export default function Screenshots() {
  const dispatch = useDispatch();
  const { worldPack: pack } = useWorldPack();
  const [fileList, setFileList] = useState<ScreenshotFile[]>([
    ...screenshotToFile(pack.screenshots),
  ]);

  useEffect(() => {
    const length = fileList.length;
    if (length > 5) {
      message.warning(
        `You uploaded more than 5 screenshots. Please delete ${
          length - 5
        } screenshots.`
      );
    }
    updateWorldPack({
      dispatch,
      key: "screenshots",
      value: fileToScreenshot(fileList),
      pack,
    });
  }, [fileList]);

  return (
    <div className="container justify-start align-start full-width gap-16 scroll">
      <div style={{ flex: 1 }}>
        <UploadImage
          type="jpeg"
          defaultFiles={[...screenshotToFile(pack.screenshots)]}
          multiple
          upload={(url) =>
            setFileList((prev) => [
              ...prev,
              {
                uid: uuidv4(),
                url: url,
                name: "",
              },
            ])
          }
          remove={(uid) => setFileList(removeScreenshot(fileList, uid))}
          title="Click or drag file to this area to upload (Max: 5)"
          description="Upload the screenshots here. jpg (1920x1080) files only"
          showUploadList
        />
      </div>
      <div
        className="container column"
        style={{
          position: "sticky",
          top: 0,
        }}
      >
        {pack.screenshots.length !== 0 && (
          <div
            style={{
              position: "relative",
              width: "800px",
              overflow: "hidden",
            }}
          >
            <Tag
              color={fileList.length <= 5 ? "green" : "red"}
              style={{ position: "absolute", top: 12, left: 12, zIndex: 10 }}
            >
              {fileList.length}/5 screenshots
            </Tag>
            <Carousel autoplay className="carousel">
              {pack.screenshots.map((screenshot, index) => (
                <img
                  src={screenshot.url}
                  alt={`Screenshot ${index}`}
                  key={screenshot.uid}
                />
              ))}
            </Carousel>
          </div>
        )}
        {fileList.length > 5 && (
          <Alert
            message={`You uploaded too many screenshots. Please delete ${
              fileList.length - 5
            } screenshot/s.`}
            type="error"
            showIcon
          />
        )}
      </div>
    </div>
  );
}
