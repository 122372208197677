import { PackTag } from "../interfaces/packCommon";

// export const TAGS: PackTag[] = [
//   { value: "crc", color: "gold", label: "CRC" },
//   { value: "ame", color: "magenta", label: "Ame" },
//   { value: "eddie", color: "cyan", label: "Eddie" },
//   { value: "prc", color: "volcano", label: "PRC" },
//   { value: "rss", color: "red", label: "RSS" },
//   { value: "merryweather", color: "green", label: "Merryweather" },
//   { value: "555comic", color: "blue", label: "555Comic" },
// ];

export const TAGS: PackTag[] = [];

export function getColor(tag: string): string {
  const color = TAGS.find((t) => tag.toLowerCase() === t.value);
  return color ? color.color : "";
}

export function keyToTag(key: string): PackTag {
  return (
    TAGS.find((t) => key.toLowerCase() === t.value) || {
      value: "555comic",
      color: "blue",
      label: "555Comic",
    }
  );
}
