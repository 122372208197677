import { useState, useEffect, useMemo } from "react";
import { Card, Checkbox, Divider, Spin, message } from "antd";
import { PACK_TYPES } from "../../utils/common";
import { getSkinPackCollections } from "../../firebase/firestore";
import { useDispatch } from "react-redux";
import SkinPackList from "./Skins/SkinPackList";
import { Filter } from "../../interfaces/common";
import { filterSkinPacks } from "../../utils/filters";
import Filters from "../../components/Packs/Filters";
import { Helmet } from "react-helmet";
import useSkinPackCollection from "../../hooks/useSkinPackCollection";
import useUser from "../../hooks/useUser";

export default function Packs() {
  const [packType, setPackType] = useState<string>(PACK_TYPES.skin_pack);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filter>({
    search: "",
    tags: [],
    status: "",
  });
  const { skinPacks } = useSkinPackCollection();
  const { currentUser: user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (packType === PACK_TYPES.skin_pack) {
      setLoading(true);
      getSkinPackCollections(dispatch, user.skinPacks, seeAll).then(
        (result) => {
          setLoading(false);
          !result && message.error("Something went wrong, try again");
        }
      );
    }
  }, [packType, seeAll]);

  useEffect(() => {
    if (packType === PACK_TYPES.skin_pack) {
      setLoading(true);
      getSkinPackCollections(dispatch, user.skinPacks).then((result) => {
        setLoading(false);
        !result && message.error("Something went wrong, try again");
      });
    }
  }, []);

  const filteredSkinPacks = useMemo(
    () => filterSkinPacks(skinPacks, filters),
    [filters, skinPacks]
  );

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Packs</title>
      </Helmet>
      <Card
        title={
          <>
            <div className="container full-width justify-start align-center">
              {/* <Segmented
                size="large"
                onChange={(value) => setPackType(value.toString())}
                options={[
                  {
                    label: "Skin Packs",
                    value: PACK_TYPES.skin_pack,
                  },
                  {
                    label: "World Packs",
                    value: PACK_TYPES.world_template,
                    disabled: true,
                  },
                ]}
              /> */}
              <Filters
                setFilter={(value) => setFilters(value)}
                filters={filters}
              />
              {user.admin && (
                <>
                  <Divider type="vertical" style={{ height: 32 }} />
                  <Checkbox onChange={(e) => setSeeAll(e.target.checked)}>
                    See All
                  </Checkbox>
                </>
              )}
            </div>
          </>
        }
        style={{ width: "100%" }}
      >
        <div className="container full-width justify-start align-start">
          {loading ? (
            <div className="container full-width justify-center align-center">
              <Spin size="large" />
            </div>
          ) : packType === PACK_TYPES.skin_pack ? (
            <SkinPackList packs={filteredSkinPacks} />
          ) : (
            "World"
          )}
        </div>
      </Card>
    </>
  );
}
