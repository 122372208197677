import {
  Button,
  Card,
  Popconfirm,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import CardHeader from "../../../components/TexturePackCreator/CardHeader";
import BlockTexture from "../../../components/TexturePackCreator/BlockTexture";
import blocks_list from "../../../assets/blocks_list.json";
import { useEffect, useMemo, useState } from "react";
import TextureUpload from "../../../components/TexturePackCreator/TextureUpload";
import useTPCreator from "../../../hooks/useTPCreator";
import { getTPSlots } from "../../../firebase/texturePackCreator";
import useUser from "../../../hooks/useUser";
import { useSearchParams } from "react-router-dom";
import { BlockList } from "../../../interfaces/texturePackCreator";
import BlockTextureCompare from "../../../components/TexturePackCreator/BlockTextureCompare";

interface DataType {
  status: string;
  blockID: string;
  filePath: string;
  new_texture: string;
}

export default function TexturePackCreator() {
  const [searchParams, _] = useSearchParams();
  const {
    TPCreator,
    addTextureToPackID,
    createSlot,
    setSlots,
    deleteTextureToPackID,
    deleteSlot,
  } = useTPCreator();
  const { currentUser } = useUser();
  const [slot, setSlot] = useState(Number(searchParams.get("slot")) || 1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getTPSlots(currentUser.uid).then((res) => setSlots(res));
  }, [currentUser]);

  useEffect(() => {
    const slot = Number(searchParams.get("slot")) || 1;
    if (slot > TPCreator.amountSlots) return setSlot(1);
    setSlot(slot);
  }, [searchParams, TPCreator.amountSlots]);

  useEffect(() => {
    const TPSlot = TPCreator.slots?.[slot];
    if (TPSlot?.packID) return;
    const packID = crypto.randomUUID();
    createSlot(slot, packID);
  }, [slot, TPCreator]);

  const { packID, textures } = useMemo(() => {
    const TPSlot = TPCreator.slots?.[slot];
    if (TPSlot?.packID) return TPSlot;
    return { packID: "", textures: {} };
  }, [TPCreator, slot]);

  const blocks: DataType[] = useMemo(() => {
    const blocksList: BlockList = JSON.parse(JSON.stringify(blocks_list));
    return Object.entries(blocksList).map(([_key, block]) => {
      const new_texture = textures?.[block.blockID] ?? "";
      return {
        ...block,
        status: new_texture ? "Done" : "Pending",
        new_texture,
      };
    });
  }, [textures]);

  const filteredBlocks = useMemo(
    () =>
      blocks.filter((block) =>
        block.blockID.toLowerCase().includes(query.toLowerCase())
      ),
    [query, blocks]
  );

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (
        <Tag color={value === "Pending" ? "red" : "green"}>
          {value || "Pending"}
        </Tag>
      ),
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Done",
          value: "Done",
        },
      ],
      filterMode: "menu",
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Block ID",
      dataIndex: "blockID",
      render: (value) => (
        <Typography.Paragraph code type="warning">
          {value}
        </Typography.Paragraph>
      ),
      sorter: (a, b) => b.blockID.localeCompare(a.blockID),
    },
    {
      title: "Original Texture",
      dataIndex: "filePath",
      render: (url, data) => (
        <BlockTexture
          src={`/assets/blocks/${url}`}
          alt={data.blockID}
          withPreview
          withGridView
          className="block-texture"
        />
      ),
    },
    {
      title: "New Texture",
      dataIndex: "new_texture",
      render: (url, data) => {
        if (url)
          return (
            <BlockTexture
              src={url}
              alt={data.blockID}
              withPreview
              withGridView
              className="block-texture"
            />
          );
        return (
          <TextureUpload
            filePath={data.filePath}
            blockID={data.blockID}
            packID={packID}
            addTextureFn={addTextureToPackID}
            tooltip="Add new texture"
            buttonProps={{
              icon: <PlusOutlined />,
              size: "large",
              type: "dashed",
            }}
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => (
        <div className="container gap-8">
          <BlockTextureCompare
            alt={data.blockID}
            originalTexture={`/assets/blocks/${data.filePath}`}
            newTexture={data.new_texture}
          />
          <TextureUpload
            filePath={data.filePath}
            blockID={data.blockID}
            packID={packID}
            addTextureFn={addTextureToPackID}
            tooltip="Change texture"
            buttonProps={{
              icon: <EditOutlined />,
              type: "text",
              disabled: !data.new_texture,
            }}
          />
          <Popconfirm
            title="Delete texture"
            description="Are you sure you want to delete this texture?"
            okText="Yes, delete"
            okType="danger"
            icon={<DeleteOutlined style={{ color: "#cc0000" }} />}
            onConfirm={() => {
              deleteTextureToPackID(packID, data.blockID);
            }}
          >
            <Tooltip title="Delete texture">
              <Button
                icon={<DeleteOutlined />}
                type="text"
                danger
                disabled={!data.new_texture}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Texture Pack Creator</title>
      </Helmet>
      <Card
        title={
          <CardHeader
            setQuery={setQuery}
            query={query}
            setSlot={setSlot}
            slotAmount={TPCreator.amountSlots}
            defaultSlot={slot}
            deleteSlot={() => deleteSlot(slot, packID)}
            packID={packID}
            addTextureFn={addTextureToPackID}
          />
        }
      >
        <Table
          columns={columns}
          dataSource={filteredBlocks.map((block) => ({
            ...block,
            key: block.blockID,
          }))}
        />
      </Card>
    </>
  );
}
