import React, { useEffect, useState } from "react";
import { Button, Result, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import useResourcePack from "../../../hooks/useResourcePack";
import {
  createResourcePackZip,
  newResourcePack,
} from "../../../utils/resourcePacker";

export default function ResourcePackSuccess() {
  const { resourcePack: pack, setResourcePack } = useResourcePack();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!pack.title) {
      navigate("/packer/resource");
    }
  }, []);

  function resetPack() {
    setResourcePack(newResourcePack());
    navigate("/packer/resource");
  }

  return (
    <main className="container justify-center align-center full-width full-height">
      <Result
        style={{ width: "50%" }}
        status="success"
        title="The package is ready to be packed"
        subTitle="You can now download you pack."
        extra={[
          <Button
            type="primary"
            key="download"
            icon={<DownloadOutlined />}
            onClick={async () => {
              setLoading(true);
              await createResourcePackZip(pack).then(() => setLoading(false));
            }}
            loading={loading}
          >
            Pack
          </Button>,
          <Button key="return" onClick={() => navigate("/packer/resource")}>
            Return
          </Button>,
          <Popconfirm
            placement="bottomRight"
            title="Are you sure?"
            description="This action will reset the previous pack"
            onConfirm={() => resetPack()}
            okText="Yes"
            cancelText="No"
            key="reset"
          >
            <Button type="dashed" danger>
              New pack
            </Button>
          </Popconfirm>,
        ]}
      ></Result>
    </main>
  );
}
