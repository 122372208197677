import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Result,
  Popconfirm,
  Progress,
  Typography,
  Space,
  message,
  Select,
  Tag,
  Input,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CloudUploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { createSkinPackZip, newSkinPack } from "../../../utils/skinPacker";
import { decodeImage } from "../../../utils/common";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import { Skin, SkinPackFB } from "../../../interfaces/skinPacker";
import {
  deleteKeyart,
  deleteSkin,
  setSkinPackFB,
} from "../../../firebase/firestore";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { TAGS, getColor } from "../../../utils/packTags";
import { PublishData } from "../../../interfaces/packCommon";
import useSkinPack from "../../../hooks/useSkinPack";
import useSkinPackCollection from "../../../hooks/useSkinPackCollection";
import useUser from "../../../hooks/useUser";

export default function PackSuccess() {
  const navigate = useNavigate();
  const [publishing, setPublishing] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [publishData, setPublishData] = useState<PublishData>({
    tags: [],
    description: "",
    status: "in_progress",
  });
  const { skinPack: pack, setSkinPack } = useSkinPack();
  const { skinPacks } = useSkinPackCollection();
  const { currentUser: user } = useUser();

  useEffect(() => {
    if (!pack.title) {
      navigate("/packer/skins");
    }
  }, []);

  function resetPack() {
    setSkinPack(newSkinPack());
    navigate("/packer/skins");
  }

  const currentPack = useMemo(() => {
    const currentPack = skinPacks.find((p) => p.pack.uuid === pack.uuid);
    if (currentPack) {
      setPublishData({
        description: currentPack?.description,
        tags: currentPack?.tags,
        status: currentPack?.status,
      });
    }
    return currentPack;
  }, [skinPacks, pack]);

  async function publishPack() {
    setPublishing(true);
    setUploaded(0);
    setError(false);

    let uploadSkins: Skin[] = [];
    if (currentPack) {
      const deletedSkins = currentPack.pack.skins.filter(
        (skin) => !pack.skins.some((s) => s.uid === skin.uid)
      );

      uploadSkins = pack.skins.filter(
        (skin) => !currentPack.pack.skins.some((s) => s.uid === skin.uid)
      );

      await Promise.all(
        deletedSkins.map((skin) => deleteSkin(skin.uid, pack.uuid))
      );
    } else {
      uploadSkins = [...pack.skins];
    }
    const packAux = structuredClone(pack);
    let packFB: SkinPackFB = {
      pack: {
        ...packAux,
        skins: [
          ...pack.skins.filter((skin) =>
            currentPack?.pack.skins.some((s) => s.uid === skin.uid)
          ),
        ],
      },
      tags: publishData.tags.map(
        (tag) => TAGS.find((t) => tag === t.value)?.label || tag
      ),
      description: publishData.description,
      status: publishData.status,
      schedule: "",
    };

    setTotal(uploadSkins.length);

    if (pack.keyart.url) {
      setTotal((prev) => prev + 1);
      const imageRefKeyart = ref(storage, `keyarts/${pack.uuid}`);
      const keyartTexture = await decodeImage(pack.keyart.url);
      await deleteKeyart(pack.uuid);
      await uploadBytes(imageRefKeyart, keyartTexture);
      const keyartURL = await getDownloadURL(imageRefKeyart);
      packFB.pack.keyart.url = keyartURL;
      setUploaded((prev) => prev + 1);
    }

    const uploadPromises = uploadSkins.map(async (skin) => {
      try {
        const imageRef = ref(storage, `skins/${pack.uuid}/${skin.uid}`);
        const skinTexture = await decodeImage(skin.texture); // Esperar a que se cargue la imagen
        await uploadBytes(imageRef, skinTexture);
        const url = await getDownloadURL(imageRef);
        packFB.pack.skins.push({ ...skin, texture: url });
        setUploaded((prev) => prev + 1);
      } catch (error) {
        console.error("An error occurred during the upload:", error);
        setError(true);
        message.error("An error occurred");
      }
    });

    try {
      await Promise.all(uploadPromises);
      setSkinPackFB(packFB, user);
      message.success("The pack was published successfully");
    } catch (error) {
      console.error("An error occurred during the upload:", error);
      setError(true);
      message.error("An error occurred");
    } finally {
      notification.info({
        message: "Your pack was uploaded successfully!",
        placement: "bottomRight",
        description: (
          <div>
            <a
              href={`${window.location.origin}/packs/skin_pack?uid=${pack.uuid}`}
            >
              Click here
            </a>
            <span> to view your skin pack.</span>
          </div>
        ),
      });
      setTimeout(() => {
        setPublishing(false);
      }, 3000);
    }
  }

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={getColor(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, textTransform: "capitalize" }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <main className="container justify-center align-center full-width full-height">
      <Result
        style={{ width: "50%" }}
        status="success"
        title="The package is ready to be packed"
        subTitle="You can download and/or publish it so you can share it."
        extra={[
          <Button
            type="primary"
            key="download"
            icon={<DownloadOutlined />}
            onClick={async () => {
              setLoading(true);
              await createSkinPackZip(pack).then(() => setLoading(false));
            }}
            loading={loading}
          >
            Pack
          </Button>,
          <Button key="return" onClick={() => navigate("/packer/skins")}>
            Return
          </Button>,
          <Popconfirm
            placement="bottomRight"
            title="Are you sure?"
            description="This action will reset the previous pack"
            onConfirm={() => resetPack()}
            okText="Yes"
            cancelText="No"
            key="reset"
          >
            <Button type="dashed" danger>
              New pack
            </Button>
          </Popconfirm>,
        ]}
      >
        {!publishing ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              Publish
            </Typography.Title>
            <div>
              <Typography.Text type="secondary" strong>
                Description
              </Typography.Text>
              <Input.TextArea
                maxLength={375}
                showCount
                value={publishData.description}
                autoSize={{ minRows: 4 }}
                style={{ marginBottom: 4 }}
                onChange={(e) =>
                  setPublishData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>
            <div className="container column">
              <Typography.Text type="secondary" strong>
                Tags
              </Typography.Text>
              <Select
                mode="tags"
                tagRender={tagRender}
                style={{ width: "100%" }}
                options={TAGS}
                value={publishData.tags}
                placeholder="Add tags"
                onChange={(value) =>
                  setPublishData((prev) => ({ ...prev, tags: [...value] }))
                }
              />
            </div>
            <Button
              icon={<CloudUploadOutlined />}
              type="primary"
              onClick={() => publishPack()}
            >
              Publish pack
            </Button>
          </Space>
        ) : (
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              Uploading files ({uploaded}/{total})
            </Typography.Title>
            <Progress
              percent={(uploaded * 100) / total}
              status={
                error
                  ? "exception"
                  : (uploaded * 100) / total >= 100
                  ? "success"
                  : "active"
              }
              showInfo={false}
              size={[500, 16]}
            />
          </Space>
        )}
      </Result>
    </main>
  );
}
