import { useState } from "react";
import { Image } from "antd";
import SkinsSummary from "../SkinPreview/SkinsSummary";
import SkinPreview from "../SkinPreview/SkinPreview";
import { defaultThumbnail } from "../../../settings/settings";
import useSkinPack from "../../../hooks/useSkinPack";

export default function Package() {
  const { skinPack } = useSkinPack();
  const [skinPreview, setSkinPreview] = useState<number>(0);
  return (
    <div className="container full-width column gap-8">
      <div className="container full-width justify-btw align-start gap-16">
        <div
          className="container justify-start align-start gap-8 column full-width"
          style={{ flex: 1 }}
        >
          <div className="full-width">
            <SkinsSummary buttons onClick={(index) => setSkinPreview(index)} />
          </div>
        </div>
        {skinPack.skins.length !== 0 && (
          <SkinPreview
            skin={skinPack.skins[skinPreview].texture}
            badge={skinPack.skins[skinPreview].skinType.toUpperCase()}
            badgeColor={
              skinPack.skins[skinPreview].skinType === "paid" ? "red" : "green"
            }
          />
        )}
        <Image
          height={450}
          src={skinPack.keyart.url || defaultThumbnail}
          style={{ flex: 3, borderRadius: 8 }}
          placeholder={false}
        />
      </div>
    </div>
  );
}
