import React, { useState } from "react";
import { Button, Steps, theme } from "antd";
import Title from "../../../components/WorldPacker/Steps/PackInformation";
import PackagerStep from "../../../components/PackagerStep";
import { useDispatch } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Screenshots from "../../../components/WorldPacker/Steps/Screenshots";
import { checkWorldPack, newWorldPack } from "../../../utils/worldPacker";
import PartnerArt from "../../../components/Common/PartnerArt";
import Assets from "../../../components/WorldPacker/Steps/Assets";
import UploadWorld from "../../../components/WorldPacker/Steps/UploadWorld";
import useWorldPack from "../../../hooks/useWorldPack";

export default function WorldPacker() {
  const { worldPack: pack, setWorldPack } = useWorldPack();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = [
    {
      title: "Pack information",
      content: <Title />,
    },
    {
      title: "Partner art",
      content: <PartnerArt packType="worldPack" />,
    },
    {
      title: "Screenshots",
      content: <Screenshots />,
    },
    {
      title: "Other assets",
      content: <Assets />,
    },
    {
      title: "Upload world",
      content: <UploadWorld />,
    },
  ];

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 24,
    maxHeight: 710,
  };

  const onChange = (value: number) => {
    setCurrent(value);
  };

  return (
    <>
      <Helmet>
        <title>Minecraft Content | World Packer</title>
      </Helmet>
      <Steps current={current} items={items} onChange={onChange} />
      <div style={contentStyle}>
        <PackagerStep title={steps[current].title}>
          {steps[current].content}
        </PackagerStep>
      </div>
      <div
        style={{ marginTop: 12 }}
        className="container full-width justify-end"
      >
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            size="large"
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current === 0 && (
          <Button
            type="dashed"
            size="large"
            style={{ margin: "0 8px" }}
            danger
            onClick={() => setWorldPack(newWorldPack())}
          >
            New pack
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" size="large" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            size="large"
            type="primary"
            onClick={() =>
              // cambiar a world pack
              checkWorldPack(pack, dispatch)
                ? navigate("/packer/world/success")
                : navigate("/packer/world/error")
            }
            icon={<CheckOutlined />}
          >
            Finish
          </Button>
        )}
      </div>
    </>
  );
}
