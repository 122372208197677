import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { DEFAULT_USER } from "../utils/user";
import useUser from "../hooks/useUser";

export default function E403v3() {
  const navigate = useNavigate();
  const { setCurrentUser } = useUser();
  return (
    <Result
      status="403"
      title="Sorry, your plan does not include this feature."
      subTitle={
        <div className="container column">
          <span>
            Please ask for an upgrade to the administrator. In the meantime you
            can use some of our free tools.
          </span>
          <span>Discord: FineArts | Email: m.angel.gomez98@gmail.com</span>
        </div>
      }
      extra={
        <>
          <Button onClick={() => window.location.reload()}>Reload</Button>
          <Button
            type="primary"
            onClick={() => {
              navigate("/");
              signOut(auth);
              setCurrentUser(DEFAULT_USER);
            }}
          >
            Sign in with another account
          </Button>
        </>
      }
    />
  );
}
