import { useAppDispatch, useAppSelector } from "./store";
import { PackError } from "../interfaces/packCommon";
import { setPackErrorsState } from "../store/slices/packsErrorsSlice";

export default function usePackErrors() {
  const packErrors = useAppSelector((state) => state.packErrors);
  const dispatch = useAppDispatch();

  const setPackErrors = (errors: PackError[]) => {
    dispatch(setPackErrorsState(errors));
  };

  return {
    packErrors,
    setPackErrors,
  };
}
