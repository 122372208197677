import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getRotationSetting } from "../../utils/localStorage";

const initialState: boolean = getRotationSetting();

export const rotationPlaySlice = createSlice({
  name: "rotationPlay",
  initialState,
  reducers: {
    setRotationPlayState: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export default rotationPlaySlice.reducer;

export const { setRotationPlayState } = rotationPlaySlice.actions;
