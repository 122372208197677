import { useAppDispatch, useAppSelector } from "./store";
import { setSkinPackState } from "../store/slices/skinPackSlice";
import { SkinPack } from "../interfaces/skinPacker";

export default function useSkinPack() {
  const skinPack = useAppSelector((state) => state.skinPack);
  const dispatch = useAppDispatch();

  const setSkinPack = (pack: SkinPack) => {
    dispatch(setSkinPackState(pack));
  };

  return {
    skinPack,
    setSkinPack,
  };
}
