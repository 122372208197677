export const tick = [
  "execute as @a if score @s cinematic matches 0.. run scoreboard players add @s cinematic 1",
  "execute as @a if score @s cinematic matches 0.. run function cinematics/detect_shot",
  "## Set spectator mode to players during a cinematic ↓",
  "gamemode spectator @a[tag=in_cinematic]",
];

export const setup = [
  "scoreboard objectives add cinematic dummy",
  "scoreboard objectives add shot dummy",
];

export const detect_shot = [
  "execute if score @s shot matches 1 run function cinematics/shot_1/shot",
  "## Add more shots here ↓",
  "## execute if score @s shot matches 2 run function cinematics/shot_2/shot ",
  "## execute if score @s shot matches 3 run function cinematics/shot_3/shot",
];

export const start = [
  "tag @s add in_cinematic",
  "scoreboard players set @s shot 1",
  "scoreboard players set @s cinematic 0",
  "## Add more commands for the start of the cinematic here ↓",
];

export const end = [
  "tag @s remove in_cinematic",
  "scoreboard players reset @s cinematic",
  "## Set adventure mode to players at the end of a cinematic ↓",
  "gamemode a @s",
  "## Add more commands for the end of the cinematic here ↓",
];

export const tickJSON = {
  values: ["cinematics/tick"],
};

export const how_to_use = [
  "Place this folder inside the 'functions' folder in your behavior pack.",
  "Get in-game and execute the following command in chat:",
  "",
  "> /function cinematics/setup",
  "",
  "To start a cinematic, execute the following command (replace the username with the player you want to play the cinematic on):",
  "",
  "> /execute as <username> run function cinematics/shot_1/start",
  "",
  "There are more instructions inside the function files to further expand the amount of shots and to add more commands.",
];
