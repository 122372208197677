import { Tabs, Empty } from "antd";
import SkinSettings from "../SkinPreview/SkinSettings";
import SkinsSummary from "../SkinPreview/SkinsSummary";
import useSkinPack from "../../../hooks/useSkinPack";

export default function SkinsSettings() {
  const { skinPack: pack } = useSkinPack();
  return (
    <>
      {pack.skins.length !== 0 ? (
        <div className="container full-width full-height justify-start align-start gap-16">
          <Tabs
            tabPosition="left"
            tabBarStyle={{ minWidth: 200, maxHeight: 600 }}
            tabBarGutter={8}
            animated
            size="large"
            style={{ flex: 3 }}
            items={pack.skins.map((skin, index) => {
              return {
                label: skin.name || `Skin ${index + 1}`,
                key: skin.uid,
                children: <SkinSettings skin={skin} />,
              };
            })}
          />
          <SkinsSummary />
        </div>
      ) : (
        <div className="container full-width justify-center align-center">
          <Empty
            description="No skins uploaded yet"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      )}
    </>
  );
}
