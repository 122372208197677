import { App, Button, Upload, Typography } from "antd";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { uploadTexture } from "../../firebase/texturePackCreator";
import { RcFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import blocks_list from "../../assets/blocks_list.json";
import { BlockList } from "../../interfaces/texturePackCreator";

interface Props {
  packID: string;
  addTextureFn: (packID: string, blockID: string, textureURL: string) => void;
}

export default function TexturesUpload({ packID, addTextureFn }: Props) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const { modal } = App.useApp();

  // const handleUpload = (file: RcFile) => {
  //   setLoading(true);
  //   uploadTexture(file, filePath, packID)
  //     .then((textureURL) => {
  //       addTextureFn(packID, blockID, textureURL);
  //     })
  //     .catch((error) => console.error(error))
  //     .finally(() => setLoading(false));
  // };

  useEffect(() => {
    if (fileList.length === 0) return;
    setLoading(true);
    const errorFiles: string[] = [];
    const blocksList: BlockList = JSON.parse(JSON.stringify(blocks_list));
    Promise.all(
      fileList.map((file) => {
        const blockID = file.name.replace(".png", "");
        const filePath = blocksList?.[blockID]?.filePath ?? "";
        if (filePath) {
          return uploadTexture(file, filePath, packID)
            .then((textureURL) => {
              addTextureFn(packID, blockID, textureURL);
            })
            .catch((error) => console.error(error));
        }
        errorFiles.push(file.name);
        return null;
      })
    )
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
        setFileList([]);
        if (errorFiles.length !== 0) {
          modal.warning({
            title: "Invalid file names. The file name should be its block ID.",
            content: (
              <Typography>
                <Typography.Paragraph>
                  <pre className="scroll">{errorFiles.join("\n")}</pre>
                </Typography.Paragraph>
              </Typography>
            ),
          });
        }
      });
  }, [fileList]);

  return (
    <Upload
      disabled={loading}
      accept="image/png"
      showUploadList={false}
      beforeUpload={(_, fileList) => {
        setFileList(fileList);
        return false;
      }}
      multiple
    >
      <Button
        size="large"
        icon={!loading ? <CloudUploadOutlined /> : <LoadingOutlined />}
        disabled={loading}
      >
        Upload textures
      </Button>
    </Upload>
  );
}
