import { saveAs } from "file-saver";
import JSZip from "jszip";
import {
  detect_shot,
  end,
  how_to_use,
  setup,
  start,
  tick,
  tickJSON,
} from "./commandFiles";

export function generateMCFunctions(shot: string) {
  const jszip = new JSZip();
  const cinematicsFolder = jszip.folder("cinematics");
  if (cinematicsFolder && jszip) {
    const shot_1 = cinematicsFolder.folder("shot_1");
    jszip.file("tick.json", JSON.stringify(tickJSON));
    jszip.file("INSTRUCTIONS.txt", how_to_use.join("\n"));
    cinematicsFolder.file("detect_shot.mcfunction", detect_shot.join("\n"));
    cinematicsFolder.file("tick.mcfunction", tick.join("\n"));
    cinematicsFolder.file("setup.mcfunction", setup.join("\n"));
    shot_1?.file("shot.mcfunction", shot);
    shot_1?.file("start.mcfunction", start.join("\n"));
    shot_1?.file("end.mcfunction", end.join("\n"));
    return jszip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `functions.zip`);
    });
  }
}
