import { db, storage } from "./firebase";
import {
  setDoc,
  getDoc,
  doc,
  collection,
  getDocs,
  updateDoc,
  serverTimestamp,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
  query,
  where,
  increment,
} from "firebase/firestore";
import { SkinPack, SkinPackFB } from "../interfaces/skinPacker";
import { Dispatch } from "redux";
import { User } from "../interfaces/common";
import { deleteObject, ref } from "firebase/storage";
import { Calendar, CalendarEvent } from "../interfaces/calendar";
import { v4 as uuidv4 } from "uuid";
import { PackHeader } from "../interfaces/packCommon";
import { setSkinPacksState } from "../store/slices/skinPacksSlice";
import { setUsersState } from "../store/slices/usersSlice";
import { setUser } from "../store/slices/userSlice";

export const COLLECTIONS = {
  USERS: "users",
  SKIN_PACKS: "skin_pack",
  ROLES: "roles",
  CALENDAR: "calendar",
  TPCREATOR: "tp_creator",
  USER_CONFIG: "user_config",
};

export async function setSkinPackFB(pack: SkinPackFB, user: User) {
  const packCollectionRef = doc(db, COLLECTIONS.SKIN_PACKS, pack.pack.uuid);
  await addUserSkinPack(pack.pack.uuid, user);
  return await setDoc(packCollectionRef, {
    pack,
    lastUpdate: serverTimestamp(),
    lastUpdateBy: user.email,
  });
}

export async function updateSkinPackSchedule(
  pack: SkinPackFB,
  schedule: string,
  user: User
) {
  if (pack.schedule !== "") {
    removeCalendarEvent(pack.schedule, pack.pack.uuid, user.uid);
  }
  if (schedule !== "") {
    addCalendarEvent(
      schedule,
      {
        label: `${pack.pack.title}`,
        color: "red",
        locked: true,
      },
      user.uid,
      pack.pack.uuid
    );
  }
  const packCollectionRef = doc(db, COLLECTIONS.SKIN_PACKS, pack.pack.uuid);
  return await updateDoc(packCollectionRef, {
    "pack.schedule": schedule,
    lastUpdate: serverTimestamp(),
    lastUpdateBy: user.email,
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

export async function updateSkinPackStatus(
  pack: SkinPackFB,
  status: string,
  user: User
) {
  const collectionRef = doc(db, COLLECTIONS.SKIN_PACKS, pack.pack.uuid);
  return await updateDoc(collectionRef, {
    "pack.status": status,
    lastUpdate: serverTimestamp(),
    lastUpdateBy: user.email,
  })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}

export async function deleteSkinPackFB(packUid: string) {
  const userCollectionRef = doc(db, COLLECTIONS.SKIN_PACKS, packUid);
  return await deleteDoc(userCollectionRef);
}

export async function getSkinPackCollections(
  dispatch: Dispatch,
  packIds: string[],
  all?: boolean
): Promise<boolean> {
  try {
    if (packIds.length === 0 && !all) return true;
    else {
      let q;
      if (all) {
        q = query(collection(db, COLLECTIONS.SKIN_PACKS));
      } else {
        q = query(
          collection(db, COLLECTIONS.SKIN_PACKS),
          where("pack.pack.uuid", "in", packIds)
        );
      }
      const querySnapshot = await getDocs(q);
      const packList: SkinPackFB[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data().pack as SkinPackFB;
        packList.push(data);
      });
      dispatch(setSkinPacksState(packList));
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function getUserFirebase(email: string) {
  try {
    const userCollectionRef = doc(db, COLLECTIONS.USERS, email);

    return getDoc(userCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().user;
      }
      return null;
    });
  } catch (error) {
    console.log(error);
  }
}

async function getSkinPackFirebase(pack: string) {
  try {
    const skinCollectionRef = doc(db, COLLECTIONS.SKIN_PACKS, pack);

    return getDoc(skinCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().pack.pack;
      }
      return null;
    });
  } catch (error) {
    console.log(error);
  }
}

async function createUser(user: User) {
  try {
    if (user.email === "") return null;
    const userCollectionRef = doc(db, COLLECTIONS.USERS, user.email);
    const userDoc = await getUserFirebase(user.email);
    if (userDoc === null) {
      return await setDoc(userCollectionRef, {
        user,
        lastUpdate: serverTimestamp(),
      });
    }
  } catch (error) {
    console.log(error);
  }
}

async function updateUserData(user: User) {
  try {
    const userCollectionRef = doc(db, COLLECTIONS.USERS, user.email);

    const userData = await getDoc(userCollectionRef).then(() => {
      updateDoc(userCollectionRef, {
        user,
        lastUpdate: serverTimestamp(),
      });
    });

    return userData;
  } catch (error) {
    console.log(error);
  }
}

export async function currentUser(user: User, dispatch: Dispatch) {
  const authorized: string[] = await getAuthorizedUsers();
  const canEdit: string[] = await getCanEditUsers();
  const admins: string[] = await getAdminUsers();
  try {
    if (user.email) {
      let userData: User = await getUserFirebase(user.email);
      if (userData !== null) {
        userData = {
          ...userData,
          photoURL: user.photoURL,
          displayName: user.displayName,
          uid: user.uid,
          authorized: authorized.includes(user.email),
          canEdit: canEdit.includes(user.email),
          admin: admins.includes(user.email),
        };
        updateUserData(userData);
        dispatch(setUser(userData));
      } else {
        createUser(user);
        dispatch(setUser(user));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getAuthorizedUsers(): Promise<string[]> {
  try {
    const rolesCollectionRef = doc(db, COLLECTIONS.ROLES, "authorized");

    return await getDoc(rolesCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().emails;
      }
      return [];
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCanEditUsers(): Promise<string[]> {
  try {
    const rolesCollectionRef = doc(db, COLLECTIONS.ROLES, "canEdit");

    return await getDoc(rolesCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().emails;
      }
      return [];
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAdminUsers(): Promise<string[]> {
  try {
    const rolesCollectionRef = doc(db, COLLECTIONS.ROLES, "admins");

    return await getDoc(rolesCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().emails;
      }
      return [];
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getBlacklisted(): Promise<string[]> {
  try {
    const rolesCollectionRef = doc(db, COLLECTIONS.ROLES, "blacklist");

    return await getDoc(rolesCollectionRef).then((result) => {
      if (result.exists()) {
        return result.data().emails;
      }
      return [];
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getUsersCollection(dispatch: Dispatch): Promise<boolean> {
  try {
    const querySnapshot = await getDocs(collection(db, COLLECTIONS.USERS));
    const usersList: User[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data().user as User;
      usersList.push(data);
    });
    dispatch(setUsersState(usersList));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteUserDoc(email: string) {
  await deleteDoc(doc(db, COLLECTIONS.USERS, email));
}

export async function deleteSkin(skinUid: string, packUid: string) {
  const skinRef = ref(storage, `skins/${packUid}/${skinUid}`);

  // Delete the file
  await deleteObject(skinRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export async function addRole(email: string, role: string) {
  try {
    const rolesRef = doc(db, COLLECTIONS.ROLES, role);

    await getDoc(rolesRef).then(() => {
      updateDoc(rolesRef, {
        emails: arrayUnion(email),
        lastUpdate: serverTimestamp(),
      });
    });
  } catch (error) {
    console.log(error);
  }
}

export async function removeRole(email: string, role: string) {
  try {
    const rolesRef = doc(db, COLLECTIONS.ROLES, role);

    await getDoc(rolesRef).then(() => {
      updateDoc(rolesRef, {
        emails: arrayRemove(email),
        lastUpdate: serverTimestamp(),
      });
    });
  } catch (error) {
    console.log(error);
  }
}

export async function deleteKeyart(packUid: string) {
  const keyartRef = ref(storage, `keyarts/${packUid}`);

  // Delete the file
  await deleteObject(keyartRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export async function deleteSkinPack(pack: SkinPack, user: User) {
  pack.skins.forEach((skin) => deleteSkin(skin.uid, pack.uuid));
  deleteKeyart(pack.uuid);
  await deleteUserSkinPack(pack.uuid, user);
  await deleteSkinPackFB(pack.uuid);
}

export async function deleteSkinPackWithUid(packUuid: string, user: User) {
  await getSkinPackFirebase(packUuid).then((result) => {
    deleteSkinPack(result, user);
  });
}

async function deleteUserSkinPack(packUuid: string, user: User) {
  try {
    const userCollectionRef = doc(db, COLLECTIONS.USERS, user.email);
    const userData = await getDoc(userCollectionRef).then((result) => {
      if (result.exists()) {
        updateDoc(userCollectionRef, {
          "user.skinPacks": arrayRemove(packUuid),
          lastUpdate: serverTimestamp(),
        });
      }
    });

    return userData;
  } catch (error) {
    console.log(error);
  }
}

async function addUserSkinPack(packUuid: string, user: User) {
  try {
    const userCollectionRef = doc(db, COLLECTIONS.USERS, user.email);
    const userData = await getDoc(userCollectionRef).then(() => {
      updateDoc(userCollectionRef, {
        "user.skinPacks": arrayUnion(packUuid),
        lastUpdate: serverTimestamp(),
      });
    });

    return userData;
  } catch (error) {
    console.log(error);
  }
}

export async function getYearCalendar(
  year: string,
  userUid: string
): Promise<Calendar | null> {
  try {
    const collectionRef = doc(db, COLLECTIONS.CALENDAR, userUid);

    return getDoc(collectionRef).then((result) => {
      if (result.exists()) {
        return (result.data()[year] as Calendar) || null;
      }
      return null;
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function addCalendarEvent(
  date: string,
  event: CalendarEvent,
  userUid: string,
  uuid: string = uuidv4()
) {
  if (date !== "") {
    const [year, month, day] = date.split("-");
    const calendarRef = doc(db, COLLECTIONS.CALENDAR, userUid);
    const calendarDoc = await getDoc(calendarRef);
    if (calendarDoc.exists()) {
      await updateDoc(calendarRef, {
        [`${year}.${month}.${day}`]: arrayUnion(uuid),
        [`${year}.events.${uuid}`]: event,
      });
      return true;
    } else {
      await setDoc(calendarRef, {
        [year]: { [month]: { [day]: [uuid] }, events: { [uuid]: event } },
      });
      return true;
    }
  }
}

export async function removeCalendarEvent(
  date: string,
  uuid: string,
  userUid: string
) {
  if (date !== "") {
    const [year, month, day] = date.split("-");
    const calendarRef = doc(db, COLLECTIONS.CALENDAR, userUid);
    const calendarDoc = await getDoc(calendarRef);
    if (calendarDoc.exists()) {
      await updateDoc(calendarRef, {
        [`${year}.${month}.${day}`]: arrayRemove(uuid),
        [`${year}.events.${uuid}`]: deleteField(),
      });
      return true;
    }
  } else return false;
}

export async function editPackHeader(
  uuid: string,
  { title, description }: PackHeader
) {
  if (title !== "" || description !== "") {
    const packRef = doc(db, COLLECTIONS.SKIN_PACKS, uuid);
    const packDoc = await getDoc(packRef);
    if (packDoc.exists()) {
      await updateDoc(packRef, {
        "pack.pack.title": title,
        "pack.description": description,
      });
      return true;
    }
  } else return false;
}

export async function editTPCreatorSlots(
  action: "increment" | "decrement",
  userUID: string
) {
  const docRef = doc(db, COLLECTIONS.USER_CONFIG, userUID);
  const userConfigDoc = await getDoc(docRef);
  if (userConfigDoc.exists()) {
    return await updateDoc(docRef, {
      amountSlots: increment(action === "increment" ? 1 : -1),
    });
  } else {
    return await setDoc(docRef, {
      amountSlots: action === "increment" ? 4 : 2,
    });
  }
}
