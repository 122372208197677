import { Filter, Roles, User } from "../interfaces/common";
import { SkinPackFB } from "../interfaces/skinPacker";
import { USER_TYPES } from "./user";

export function filterUsers(
  usersCollection: User[],
  query: string,
  tab: string,
  roles: Roles
) {
  let users = [...usersCollection].sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  if (query) {
    users = users.filter(
      (user) =>
        user.displayName.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase())
    );
  }
  if (tab === USER_TYPES.UNAUTHORIZED) {
    users = users.filter((user) => !roles.authorized.includes(user.email));
  }
  if (tab === USER_TYPES.AUTHORIZED) {
    users = users.filter((user) => roles.authorized.includes(user.email));
  }
  if (tab === USER_TYPES.CAN_EDIT) {
    users = users.filter((user) => roles.canEdit.includes(user.email));
  }
  if (tab === USER_TYPES.ADMINS) {
    users = users.filter((user) => roles.admins.includes(user.email));
  }
  return users;
}

export function filterSkinPacks(
  packs: SkinPackFB[],
  filters: Filter
): SkinPackFB[] {
  let packsAux = [...packs];
  if (filters.search) {
    packsAux = packsAux.filter(
      (pack) =>
        pack.pack.title.toLowerCase().includes(filters.search.toLowerCase()) ||
        pack.description.toLowerCase().includes(filters.search.toLowerCase())
    );
  }
  if (filters.tags.length !== 0) {
    packsAux = packsAux.filter((pack) =>
      pack.tags.some((tag) => filters.tags.includes(tag.toLowerCase()))
    );
  }

  if (filters.status) {
    packsAux = packsAux.filter((pack) => pack.status === filters.status);
  }
  return packsAux;
}
