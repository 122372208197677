import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { newSkinPack } from "../../utils/skinPacker";
import { SkinPack } from "../../interfaces/skinPacker";

const initialState: SkinPack = newSkinPack();

export const skinPackSlice = createSlice({
  name: "skinPack",
  initialState,
  reducers: {
    setSkinPackState: (state, action: PayloadAction<SkinPack>) => {
      return action.payload;
    },
  },
});

export default skinPackSlice.reducer;

export const { setSkinPackState } = skinPackSlice.actions;
