import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Button, Menu, Popconfirm, Tooltip, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  PoweroffOutlined,
  AppstoreOutlined,
  InboxOutlined,
  UserOutlined,
  CalendarOutlined,
  SkinOutlined,
  RocketOutlined,
  ToolOutlined,
  VideoCameraOutlined,
  FormatPainterOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { DEFAULT_USER } from "../../utils/user";
import { Link, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";

export default function Sidebar() {
  const { currentUser, setCurrentUser } = useUser();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [currentPath, setCurtentPath] = useState<string[]>([]);
  const location = useLocation();
  const items: MenuProps["items"] = [
    {
      key: "packs",
      icon: <AppstoreOutlined />,
      label: <Link to="/packs">Packs</Link>,
    },
    {
      key: "calendar",
      icon: <CalendarOutlined />,
      label: <Link to="/calendar">Calendar</Link>,
    },
    {
      key: "packer",
      icon: <InboxOutlined />,
      label: "Packer",
      children: [
        {
          key: "skins",
          icon: <SkinOutlined />,
          label: <Link to="/packer/skins">Skin Pack</Link>,
        },
        {
          key: "world",
          icon: <RocketOutlined />,
          label: <Link to="/packer/world">World Pack</Link>,
        },
        {
          key: "resource",
          icon: <ClearOutlined />,
          label: <Link to="/packer/resource">Resource/Behavior Pack</Link>,
        },
      ],
    },
    {
      key: "tools",
      icon: <ToolOutlined />,
      label: "Tools",
      children: [
        {
          key: "cinematic-generator",
          icon: <VideoCameraOutlined />,
          label: (
            <Link to="/tools/cinematic-generator">Cinematic Generator</Link>
          ),
        },
        {
          key: "texture-pack-creator",
          icon: <FormatPainterOutlined />,
          label: (
            <Link to="/tools/texture-pack-creator">Texture Pack Creator</Link>
          ),
        },
      ],
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: <Link to="/users">Users</Link>,
    },
  ];

  const canEditRequired: string[] = [];
  const adminRequired: string[] = ["users"];

  const sidebarOptions: MenuProps["items"] = useMemo(() => {
    let options = [...items];
    if (!currentUser.canEdit) {
      options = options.filter(
        (item) => !canEditRequired.includes(item?.key as string)
      );
    }
    if (!currentUser.admin) {
      options = options.filter(
        (item) => !adminRequired.includes(item?.key as string)
      );
    }
    return options;
  }, [currentUser]);

  useEffect(() => {
    setCurtentPath(location.pathname.split("/") || "packs");
  }, [location]);

  return (
    <Sider
      style={{
        paddingTop: 16,
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
    >
      <div className="container justify-btw column full-height">
        <Menu
          mode="inline"
          items={sidebarOptions}
          selectedKeys={[...currentPath]}
        />
        {!collapsed ? (
          <div
            className="container justify-start align-center full-width gap-2"
            style={{ padding: "0px 12px" }}
          >
            <Avatar src={currentUser.photoURL} size="large" />
            <div className="container justify-center align-start column">
              <Tooltip title={currentUser.email} placement="right">
                <Popconfirm
                  title="Log out"
                  description="Are you sure you want to log out?"
                  okText="Yes, log out"
                  okType="danger"
                  icon={<PoweroffOutlined style={{ color: "#cc0000" }} />}
                  onConfirm={() => {
                    signOut(auth);
                    setCurrentUser(DEFAULT_USER);
                  }}
                >
                  <Button type="text" style={{ padding: "4px 8px" }}>
                    {currentUser.displayName}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div
            className="container justify-center align-center full-width gap-2"
            style={{ padding: "0px 12px" }}
          >
            <Popconfirm
              title="Log out"
              description="Are you sure you want to log out?"
              okText="Yes, log out"
              okType="danger"
              icon={<PoweroffOutlined style={{ color: "#cc0000" }} />}
              onConfirm={() => {
                signOut(auth);
                setCurrentUser(DEFAULT_USER);
              }}
            >
              <Tooltip title={currentUser.email} placement="right">
                <Avatar
                  src={currentUser.photoURL}
                  size="large"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        )}
      </div>
    </Sider>
  );
}
