import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SkinFile } from "../../../interfaces/skinPacker";
import {
  removeSkin,
  updateSkinPack,
  fileToSkin,
  skinToFile,
} from "../../../utils/skinPacker";
import { v4 as uuidv4 } from "uuid";
import UploadImage from "../../Common/UploadImage";
import useSkinPack from "../../../hooks/useSkinPack";

export default function SkinUpload() {
  const { skinPack: pack } = useSkinPack();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState<SkinFile[]>([
    ...skinToFile(pack.skins),
  ]);

  useEffect(() => {
    updateSkinPack({
      dispatch,
      key: "skins",
      value: fileToSkin(fileList),
      pack,
    });
  }, [fileList]);

  return (
    <div className="full-width full-height scroll">
      <UploadImage
        type="png"
        defaultFiles={[...skinToFile(pack.skins)]}
        multiple
        upload={(url) =>
          setFileList((prev) => [
            ...prev,
            {
              uid: uuidv4(),
              url: url,
              name: "",
              skinType: "paid",
              skinModel: "default",
            },
          ])
        }
        remove={(uid) => setFileList(removeSkin(fileList, uid))}
        title="Click or drag file to this area to upload"
        description="Upload the skins here. png files only"
        showUploadList
      />
    </div>
  );
}
