import { Calendar, CalendarProps, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { getYearCalendar, removeCalendarEvent } from "../../firebase/firestore";
import dayjs from "dayjs";
import { Calendar as ICalendar } from "../../interfaces/calendar";
import type { Dayjs } from "dayjs";
import AddEvent from "../../components/Calendar/AddEvent";
import { Helmet } from "react-helmet";
import useUser from "../../hooks/useUser";

export default function Schedule() {
  const { currentUser } = useUser();
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [yearCalendar, setYearCalendar] = useState<ICalendar | null>(null);
  const [calendarMode, setCalendarMode] = useState<"month" | "year">("month");

  useEffect(() => {
    setYearCalendar(null);
    fetchYearCalendar();
  }, [currentDate.split("-")[0]]);

  function fetchYearCalendar() {
    getYearCalendar(currentDate.split("-")[0], currentUser.uid).then(
      (result) => {
        setYearCalendar(result);
      }
    );
  }

  function handleRemoveEvent(date: string, uuid: string) {
    removeCalendarEvent(date, uuid, currentUser.uid).then((result) => {
      if (result) {
        message.success("Event removed from the calendar.", 5);
        fetchYearCalendar();
      } else {
        message.error("An error occured.", 5);
      }
    });
  }

  const dateCellRender = (value: Dayjs) => {
    if (!yearCalendar) {
      return;
    }
    const listData = yearCalendar[value.month() + 1]?.[value.date()];
    if (!listData) return;
    return (
      <div className="container column full-width gap-4">
        {listData.map((item, index) => (
          <Tag
            color={yearCalendar.events[item].color}
            className="full-width"
            style={{ margin: 0, minWidth: "fit-content" }}
            key={index}
            bordered={false}
            closable={!yearCalendar.events[item].locked && currentUser.canEdit}
            onClose={() =>
              handleRemoveEvent(dayjs(value).format("YYYY-M-D"), item)
            }
          >
            {yearCalendar.events[item].label}
          </Tag>
        ))}
      </div>
    );
  };

  const monthCellRender = (value: Dayjs) => {
    if (!yearCalendar) {
      return;
    }
    const listData = yearCalendar[value.month() + 1];
    if (!listData) return;
    return listData ? (
      <div className="container column full-width gap-4">
        {Object.keys(listData).map((key, index) =>
          listData[key].map((item, index) => (
            <Tag
              color={yearCalendar.events[item].color}
              className="full-width"
              style={{ margin: 0, minWidth: "fit-content" }}
              key={index}
              bordered={false}
              closable={
                !yearCalendar.events[item].locked && currentUser.canEdit
              }
              onClose={() =>
                handleRemoveEvent(dayjs(value).format("YYYY-M-D"), item)
              }
            >
              {yearCalendar.events[item].label}
            </Tag>
          ))
        )}
      </div>
    ) : null;
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Calendar</title>
      </Helmet>
      <div className="container column full-width gap-8">
        {currentUser.canEdit && (
          <AddEvent
            currentDate={currentDate}
            fetchYearCalendar={() => fetchYearCalendar()}
          />
        )}
        <Calendar
          style={{ padding: "0px 12px", borderRadius: 8 }}
          onPanelChange={(value, mode) => {
            setCurrentDate(value.format("YYYY-M-D"));
            setCalendarMode(mode);
          }}
          cellRender={cellRender}
          mode={calendarMode}
          onSelect={(value) => {
            setCurrentDate(value.format("YYYY-M-D"));
            setCalendarMode("month");
          }}
          value={dayjs(currentDate)}
        />
      </div>
    </>
  );
}
