import { useEffect, useState } from "react";
import { Screenshot } from "../../../interfaces/worldPacker";
import { v4 as uuidv4 } from "uuid";
import { updateWorldPack } from "../../../utils/worldPacker";
import { useDispatch } from "react-redux";
import UploadImage from "../../Common/UploadImage";
import { Image, Typography } from "antd";
import useWorldPack from "../../../hooks/useWorldPack";

export default function Assets() {
  const dispatch = useDispatch();
  const { worldPack: pack } = useWorldPack();
  const [panoramaList, setPanoramaList] = useState<Screenshot[]>([
    pack.panorama,
  ]);
  const [packIconList, setPackIconList] = useState<Screenshot[]>([
    pack.packIcon,
  ]);

  useEffect(() => {
    const panorama = panoramaList[0];
    updateWorldPack({
      dispatch,
      key: "panorama",
      value: { uid: panorama.uid, url: panorama.url, name: "panorama_0" },
      pack,
    });
  }, [panoramaList]);

  useEffect(() => {
    const packIcon = packIconList[0];
    updateWorldPack({
      dispatch,
      key: "packIcon",
      value: { uid: packIcon.uid, url: packIcon.url, name: "packicon_0" },
      pack,
    });
  }, [packIconList]);

  return (
    <div className="container justify-start align-start full-width gap-8 scroll column">
      <Typography.Text type="secondary" strong>
        Panorama
      </Typography.Text>
      <div className="container full-width gap-16">
        <div className="full-width">
          <UploadImage
            type="jpeg"
            defaultFiles={pack.panorama.url ? [pack.panorama] : []}
            upload={(url) =>
              setPanoramaList((prev) => [
                {
                  uid: uuidv4(),
                  url: url,
                  name: "",
                },
              ])
            }
            title="Click or drag file to this area to upload"
            description="Upload the panorama here. jpg (1000-4000x450) files only"
          />
        </div>
        <Image
          src={pack.panorama.url}
          style={{ borderRadius: 8 }}
          placeholder={false}
        />
      </div>
      <Typography.Text type="secondary" strong>
        Pack Icon
      </Typography.Text>
      <div className="container full-width gap-16">
        <div className="full-width">
          <UploadImage
            type="jpeg"
            defaultFiles={pack.packIcon.url ? [pack.packIcon] : []}
            upload={(url) =>
              setPackIconList((prev) => [
                {
                  uid: uuidv4(),
                  url: url,
                  name: "",
                },
              ])
            }
            title="Click or drag file to this area to upload"
            description="Upload the pack icon here. jpg (256x256) files only"
          />
        </div>
        <Image
          src={pack.packIcon.url}
          style={{ borderRadius: 8 }}
          placeholder={false}
        />
      </div>
    </div>
  );
}
