import { useState, useEffect } from "react";
import {
  Input,
  Image,
  Typography,
  InputNumber,
  Space,
  Button,
  Tooltip,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { updateSkinPack } from "../../../utils/skinPacker";
import { useDispatch } from "react-redux";
import { KeyartFile } from "../../../interfaces/packCommon";
import { validate, v4 as uuidv4 } from "uuid";
import { formatString } from "../../../utils/common";
import { defaultThumbnail } from "../../../settings/settings";
import UploadImage from "../../Common/UploadImage";
import useSkinPack from "../../../hooks/useSkinPack";
const { Text } = Typography;

export default function PackInformation() {
  const { skinPack: pack } = useSkinPack();
  const [fileList, setFileList] = useState<KeyartFile[]>([pack.keyart]);
  const dispatch = useDispatch();

  useEffect(() => {
    updateSkinPack({
      dispatch,
      key: "keyart",
      value: { ...fileList[0] },
      pack,
    });
  }, [fileList]);

  // const props: UploadProps = {
  //   accept: "image/jpeg",
  //   defaultFileList: [pack.keyart],
  //   showUploadList: false,
  //   onRemove: () => {
  //     setFileList([]);
  //   },
  //   beforeUpload: (file) => {
  //     const isJPG = file.type === "image/jpeg";
  //     if (!isJPG) {
  //       message.error(`${file.name} its not a jpg file`, 5);
  //       return isJPG || Upload.LIST_IGNORE;
  //     }
  //     // Convertir el Blob en un imageDataUrl
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       if (event.target) {
  //         const imageDataUrl = event.target.result as string;

  //         setFileList([
  //           {
  //             uid: file.uid,
  //             url: imageDataUrl || "",
  //             name: "",
  //           },
  //         ]);
  //       }
  //     };
  //     reader.readAsDataURL(file);

  //     return false;
  //   },
  // };

  return (
    <div className="container justify-start align-start full-width gap-16">
      <div
        className="container column justify-start align-start full-width gap-8"
        style={{ flex: 1 }}
      >
        <Input
          addonBefore="Title"
          placeholder="All Night Arcade"
          size="large"
          defaultValue={pack.title}
          showCount
          maxLength={40}
          value={pack.title}
          onBlur={() =>
            updateSkinPack({
              pack,
              key: "title",
              value: formatString(pack.title),
              dispatch,
            })
          }
          onChange={(e) =>
            updateSkinPack({
              pack,
              key: "title",
              value: e.target.value,
              dispatch,
            })
          }
        />
        <Space.Compact block size="large">
          <Input
            addonBefore="Pack UUID"
            placeholder="Pack UUID"
            size="large"
            defaultValue={pack.uuid}
            maxLength={36}
            status={!validate(pack.uuid) ? "error" : ""}
            value={pack.uuid}
            onChange={(e) =>
              updateSkinPack({
                pack,
                key: "uuid",
                value: e.target.value,
                dispatch,
              })
            }
          />
          <Tooltip title="Generate new UUID">
            <Button
              icon={<ReloadOutlined />}
              size="large"
              onClick={() =>
                updateSkinPack({
                  pack,
                  key: "uuid",
                  value: uuidv4(),
                  dispatch,
                })
              }
            />
          </Tooltip>
        </Space.Compact>
        <Space.Compact block size="large">
          <Input
            addonBefore="Modules UUID"
            placeholder="Modules UUID"
            size="large"
            defaultValue={pack.uuidModules}
            maxLength={36}
            status={!validate(pack.uuidModules) ? "error" : ""}
            value={pack.uuidModules}
            onChange={(e) =>
              updateSkinPack({
                pack,
                key: "uuidModules",
                value: e.target.value,
                dispatch,
              })
            }
          />
          <Tooltip title="Generate new UUID">
            <Button
              icon={<ReloadOutlined />}
              size="large"
              onClick={() =>
                updateSkinPack({
                  pack,
                  key: "uuidModules",
                  value: uuidv4(),
                  dispatch,
                })
              }
            />
          </Tooltip>
        </Space.Compact>
        <Space.Compact size="large">
          <InputNumber
            min={1}
            value={pack.version[0]}
            size="large"
            addonBefore="Version"
            onChange={(value) => {
              updateSkinPack({
                pack,
                key: "version",
                value: [value || 1, pack.version[1], pack.version[2]],
                dispatch,
              });
            }}
          />
          <InputNumber
            min={0}
            value={pack.version[1]}
            size="large"
            onChange={(value) => {
              updateSkinPack({
                pack,
                key: "version",
                value: [pack.version[0], value || 0, pack.version[2]],
                dispatch,
              });
            }}
          />
          <InputNumber
            min={0}
            value={pack.version[2]}
            size="large"
            onChange={(value) => {
              updateSkinPack({
                pack,
                key: "version",
                value: [pack.version[0], pack.version[1], value || 0],
                dispatch,
              });
            }}
          />
        </Space.Compact>
        {/* <Upload /> */}
        <Text type="secondary" strong>
          Keyart
        </Text>
        <div className="full-width">
          <UploadImage
            type="jpeg"
            defaultFiles={[pack.keyart]}
            upload={(url, uid) =>
              setFileList([
                {
                  uid: uid,
                  url: url || "",
                  name: "",
                },
              ])
            }
            title="Click or drag file to this area to upload"
            description="Upload the keyart here. jpg (1920x1080) files only"
          />
        </div>
      </div>
      <Image
        height={450}
        src={fileList[0].url || defaultThumbnail}
        style={{ flex: 3, borderRadius: 8 }}
        placeholder={false}
      />
    </div>
  );
}
