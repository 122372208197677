import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { WorldPack } from "../../interfaces/worldPacker";
import { newWorldPack } from "../../utils/worldPacker";

const initialState: WorldPack = newWorldPack();

export const worldPackSlice = createSlice({
  name: "worldPack",
  initialState,
  reducers: {
    setWorldPackState: (state, action: PayloadAction<WorldPack>) => {
      return action.payload;
    },
  },
});

export default worldPackSlice.reducer;

export const { setWorldPackState } = worldPackSlice.actions;
