import { useAppDispatch, useAppSelector } from "./store";
import { User } from "../interfaces/common";
import { setUsersState } from "../store/slices/usersSlice";

export default function useUserCollection() {
  const users = useAppSelector((state) => state.userCollection);
  const dispatch = useAppDispatch();

  const setUsers = (users: User[]) => {
    dispatch(setUsersState(users));
  };

  return {
    users,
    setUsers,
  };
}
