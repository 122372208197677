import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { SkinPackFB } from "../../interfaces/skinPacker";

const initialState: SkinPackFB[] = [];

export const skinPacksSlice = createSlice({
  name: "skinPackCollection",
  initialState,
  reducers: {
    setSkinPacksState: (state, action: PayloadAction<SkinPackFB[]>) => {
      return action.payload;
    },
  },
});

export default skinPacksSlice.reducer;

export const { setSkinPacksState } = skinPacksSlice.actions;
