import { useState } from "react";
import "./blockTexture.css";
import { Button, Typography } from "antd";
import {
  NumberOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

interface Props {
  src: string;
  alt: string;
  className?: string;
  withPreview?: boolean;
  withGridView?: boolean;
}

export default function BlockTexture({
  src,
  alt,
  className,
  withPreview,
  withGridView,
}: Props) {
  const [showPreview, setShowPreview] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [close, setClose] = useState(false);

  function handleClose() {
    setClose(true);
    setTimeout(() => {
      setShowPreview(false);
      setClose(false);
    }, 500);
  }

  const handleDownload = () => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${alt}.png`; // Cambia 'image.jpg' al nombre que quieras para el archivo descargado
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      {showPreview && (
        <div className={`image-preview-wrapper ${close ? "close" : ""}`}>
          <div className="image-preview-buttons-wrapper">
            <div className="image-preview-buttons">
              {withGridView && (
                <Button
                  type="primary"
                  icon={<NumberOutlined />}
                  onClick={() => setGridView(!gridView)}
                />
              )}
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleDownload}
              />

              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleClose}
              />
            </div>
          </div>
          {gridView ? (
            <div className="image-preview-grid">
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
              <img src={src} alt={alt} />
            </div>
          ) : (
            <img src={src} alt={alt} />
          )}
          <div
            className="image-preview-background"
            onClick={() => {
              handleClose();
              setTimeout(() => {
                setGridView(false);
              }, 500);
            }}
          />
          <Typography.Title level={3}>{alt}</Typography.Title>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`${className} ${withPreview ? "cursor-zoom-in" : ""}`}
        onClick={() => withPreview && setShowPreview(true)}
      />
    </>
  );
}
