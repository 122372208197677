import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/index";
import AppPage from "./pages/AppPage";
import AuthControl from "./pages/AuthControl";
import { App as AppWrap, ConfigProvider, theme } from "antd";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <Provider store={store}>
      <AppWrap>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
        >
          <BrowserRouter>
            <AuthControl>
              <AppPage />
            </AuthControl>
          </BrowserRouter>
        </ConfigProvider>
      </AppWrap>
    </Provider>
  );
}

export default App;
