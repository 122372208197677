import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces/common";
import { DEFAULT_USER } from "../../utils/user";

const initialState: User = DEFAULT_USER;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
  },
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;
