import { Pagination } from "antd";
import React, { ReactNode, useState, useMemo } from "react";

interface Props {
  defaultPageSize?: number;
  pageSizeOptions?: number[];
  hideOnSinglePage?: boolean;
  showTotal?: (total: number, range: [number, number]) => string;
  children: ReactNode[];
}

export default function Paginate({
  defaultPageSize = 10,
  pageSizeOptions = [10, 20, 50, 100],
  hideOnSinglePage,
  showTotal,
  children,
}: Props) {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);

  const items = useMemo(
    () => children.slice(page * pageSize - pageSize, page * pageSize),
    [page, pageSize, children]
  );

  return (
    <div className="container column full-width gap-16 align-center">
      <div className="container full-width gap-16 wrap">{items}</div>
      <Pagination
        pageSize={pageSize}
        hideOnSinglePage={hideOnSinglePage}
        showSizeChanger
        pageSizeOptions={pageSizeOptions}
        current={page}
        total={children.length}
        showTotal={showTotal}
        onShowSizeChange={(current, value) => setPageSize(value)}
        onChange={(value) => setPage(value)}
      />
    </div>
  );
}
