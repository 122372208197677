import { PackStatus } from "../interfaces/packCommon";

const STATUS: PackStatus = {
  in_progress: { label: "In Progress", color: "volcano" },
  reviewing: { label: "Reviewing", color: "magenta" },
  approved: { label: "Approved", color: "cyan" },
  scheduled: { label: "Scheduled", color: "red" },
  released: { label: "Released", color: "green" },
};

export default STATUS;
