import { useState } from "react";
import "./blockTexture.css";
import { Button, Typography, Tooltip } from "antd";
import { BlockOutlined, CloseOutlined } from "@ant-design/icons";

interface Props {
  alt: string;
  originalTexture: string;
  newTexture: string;
  className?: string;
}

export default function BlockTextureCompare({
  alt,
  originalTexture,
  newTexture,
  className,
}: Props) {
  const [showPreview, setShowPreview] = useState(false);
  const [close, setClose] = useState(false);

  function handleClose() {
    setClose(true);
    setTimeout(() => {
      setShowPreview(false);
      setClose(false);
    }, 500);
  }

  return (
    <>
      {showPreview && (
        <div className={`image-preview-wrapper ${close ? "close" : ""}`}>
          <div className="image-preview-buttons-wrapper">
            <div className="image-preview-buttons">
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="container gap-16">
            <img src={originalTexture} alt={alt} />
            {newTexture && <img src={newTexture} alt={alt} />}
          </div>
          <div
            className="image-preview-background"
            onClick={() => {
              handleClose();
            }}
          />
          <Typography.Title level={3}>{alt}</Typography.Title>
        </div>
      )}
      <Tooltip title="Compare textures">
        <Button
          icon={<BlockOutlined />}
          type="text"
          onClick={() => setShowPreview(true)}
          disabled={!newTexture}
        />
      </Tooltip>
    </>
  );
}
