import { useAppDispatch, useAppSelector } from "./store";
import { setWorldPackState } from "../store/slices/worldPackSlice";
import { WorldPack } from "../interfaces/worldPacker";

export default function useWorldPack() {
  const worldPack = useAppSelector((state) => state.worldPack);
  const dispatch = useAppDispatch();

  const setWorldPack = (pack: WorldPack) => {
    dispatch(setWorldPackState(pack));
  };

  return {
    worldPack,
    setWorldPack,
  };
}
