import {
  SkinPack,
  UpdateSkinPack,
  Skin,
  SkinFile,
  SkinsManifest,
  SkinPackFB,
} from "../interfaces/skinPacker";
import { Manifest, PackError } from "../interfaces/packCommon";
import { manifest } from "./manifestTemplate";
import packObject from "./packObject";
import { v4 as uuidv4, validate } from "uuid";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { message } from "antd";
import {
  decodeImage,
  generateOrValidateUuidV4,
  removeSpaces,
  resizeImage,
} from "./common";
import { Dispatch } from "redux";
import { User } from "../interfaces/common";
import {
  updateSkinPackSchedule,
  updateSkinPackStatus,
} from "../firebase/firestore";
import { setSkinPacksState } from "../store/slices/skinPacksSlice";
import { setPackErrorsState } from "../store/slices/packsErrorsSlice";
import { setSkinPackState } from "../store/slices/skinPackSlice";

export function newSkinPack(): SkinPack {
  let pack = { ...packObject };
  pack = { ...pack, uuid: uuidv4(), uuidModules: uuidv4() };
  return pack;
}

export function updateSkinPack({ pack, key, value, dispatch }: UpdateSkinPack) {
  const packAux: SkinPack = { ...pack };
  packAux[key] = value;
  dispatch(setSkinPackState(packAux));
}

export function fileToSkin(files: SkinFile[]): Skin[] {
  return files.map((file, index) => ({
    uid: file.uid,
    name: file.name || `Skin ${index + 1}`,
    texture: file.url ? file.url : "",
    skinType: file.skinType,
    skinModel: file.skinModel,
  }));
}

export function skinToFile(skins: Skin[]): SkinFile[] {
  return skins.map((skin) => ({
    uid: skin.uid,
    url: skin.texture,
    name: skin.name,
    skinType: skin.skinType,
    skinModel: skin.skinModel,
  }));
}

export function removeSkin(skins: SkinFile[], uid: string): SkinFile[] {
  const skinsAux: SkinFile[] = [...skins];
  skinsAux.splice(
    skinsAux.findIndex((skin) => skin.uid === uid),
    1
  );
  return skinsAux;
}

export function updateSkin(skins: Skin[], updateSkin: Skin): Skin[] {
  const skinsAux = [...skins];
  const skinIndex = skinsAux.findIndex((skin) => skin.uid === updateSkin.uid);
  skinsAux.splice(skinIndex, 1, updateSkin);
  return skinsAux;
}

export function createSkinTextsFile(pack: SkinPack): string {
  const lines: string[] = [];
  const localizationName = removeSpaces(pack.title);
  lines.push(`skinpack.${localizationName}=${pack.title}`);
  pack.skins.forEach((skin) =>
    lines.push(
      `skin.${localizationName}.${removeSpaces(skin.name)}=${skin.name}`
    )
  );
  return lines.join("\n");
}

export function createSkinPackManifest(pack: SkinPack): Manifest {
  const manifestTemplate = { ...manifest };
  manifestTemplate.header.name = pack.title;
  manifestTemplate.header.uuid = generateOrValidateUuidV4(pack.uuid);
  manifestTemplate.header.version = [...pack.version];
  manifestTemplate.modules[0].uuid = generateOrValidateUuidV4(pack.uuidModules);
  manifestTemplate.modules[0].type = "skin_pack";
  manifestTemplate.modules[0].version = [...pack.version];
  if (!pack.title) {
    message.warning("The pack does not have a title.", 5);
  }
  return manifestTemplate;
}

export function createSkinsManifest(pack: SkinPack): SkinsManifest {
  const skinsManifest: SkinsManifest = {
    serialize_name: "TemplateSkinPack",
    localization_name: "TemplateSkinPack",
    skins: [],
  };

  skinsManifest.serialize_name = removeSpaces(pack.title);
  skinsManifest.localization_name = removeSpaces(pack.title);
  for (const skin of pack.skins) {
    skinsManifest.skins.push({
      localization_name: removeSpaces(skin.name),
      geometry:
        skin.skinModel === "default"
          ? "geometry.humanoid.custom"
          : "geometry.humanoid.customSlim",
      texture: getTextureFileName(skin),
      type: skin.skinType,
    });
  }
  return skinsManifest;
}

export async function createSkinPackZip(pack: SkinPack): Promise<boolean> {
  const jszip = new JSZip();
  const storeArtFolder = jszip.folder("Store Art");
  const marketingArtFolder = jszip.folder("Marketing Art");
  const contentFolder = jszip.folder("Content");
  const skinPackFolder = contentFolder?.folder("skin_pack");
  const textsFolder = skinPackFolder?.folder("texts");

  const textsFile = createSkinTextsFile(pack);
  const manifest = createSkinPackManifest(pack);
  const skinsManifest = createSkinsManifest(pack);
  textsFolder?.file("en_US.lang", textsFile);
  textsFolder?.file("languages.json", JSON.stringify(["en_US"]));
  skinPackFolder?.file("manifest.json", JSON.stringify(manifest, null, 2));
  skinPackFolder?.file("skins.json", JSON.stringify(skinsManifest, null, 2));

  // Promesas para cargar imágenes
  const skinPromises = pack.skins.map(async (skin) => {
    const imageBlob = await decodeImage(skin.texture);
    skinPackFolder?.file(getTextureFileName(skin), imageBlob);
  });

  const partnerArtBlob = pack.partnerArt.url
    ? await decodeImage(pack.partnerArt.url)
    : null;

  if (partnerArtBlob) {
    marketingArtFolder?.file(
      `${removeSpaces(pack.title) + "_PartnerArt.jpg"}`,
      partnerArtBlob
    );
  } else {
    message.warning("Your package was packed without a partner art", 5);
  }

  const keyArtBlob = pack.keyart.url
    ? await decodeImage(pack.keyart.url)
    : null;

  if (keyArtBlob) {
    marketingArtFolder?.file(
      `${removeSpaces(pack.title) + "_MarketingKeyArt.jpg"}`,
      keyArtBlob
    );

    const thumbnailBlob = resizeImage(keyArtBlob, 800, 450);
    storeArtFolder?.file(
      `${removeSpaces(pack.title) + "_Thumbnail_0.jpg"}`,
      thumbnailBlob
    );
  } else {
    message.warning("Your package was packed without a keyart", 5);
  }

  // Esperar a que se resuelvan todas las promesas de carga de imágenes
  await Promise.all(skinPromises);

  return jszip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${pack.title}.zip`);
    message.success(
      `Your package was successfully downloaded with the name "${
        pack.title || "zip"
      }.zip"`,
      5
    );
    return true;
  });
}

export function getTextureFileName(skin: Skin): string {
  return `${removeSpaces(skin.name)}${
    skin.skinModel === "default" ? "_custom" : "_customSlim"
  }.png`;
}

export function checkSkinPack(pack: SkinPack, dispatch: Dispatch): boolean {
  const errors: PackError[] = [];
  const skinNames = pack.skins.map((skin) => skin.name);
  const uniqueSkinNames = new Set(skinNames);
  if (pack.skins.length === 0) {
    errors.push("You did not add any skin to the package");
  }
  if (uniqueSkinNames.size !== skinNames.length) {
    errors.push("There are skins with repeated names");
  }
  // if (!pack.keyart.url) {
  //   errors.push("You did not add the pack keyart");
  // }
  // if (!pack.partnerArt.url) {
  //   errors.push("You did not add the pack partner art");
  // }
  if (!pack.title) {
    errors.push("The package has no title");
  }
  if (!validate(pack.uuid)) {
    errors.push("The 'Pack UUID' is invalid");
  }
  if (!validate(pack.uuidModules)) {
    errors.push("The 'Modules UUID' is not valid");
  }
  dispatch(setPackErrorsState(errors));
  return errors.length === 0;
}

export async function updatePackStatus(
  pack: SkinPackFB,
  packs: SkinPackFB[],
  status: string,
  user: User,
  dispatch: Dispatch
) {
  const packsAux = [...packs];
  const packIndex = packsAux.findIndex((p) => p.pack.uuid === pack.pack.uuid);
  packsAux.splice(packIndex, 1, { ...pack, status });
  dispatch(setSkinPacksState(packsAux));
  return await updateSkinPackStatus(pack, status, user);
}

export async function updatePackSchedule(
  pack: SkinPackFB,
  packs: SkinPackFB[],
  schedule: string,
  user: User,
  dispatch: Dispatch
) {
  const packsAux = [...packs];
  const packIndex = packsAux.findIndex((p) => p.pack.uuid === pack.pack.uuid);
  packsAux.splice(packIndex, 1, { ...pack, schedule });
  dispatch(setSkinPacksState(packsAux));
  return await updateSkinPackSchedule(pack, schedule, user);
}
