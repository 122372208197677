import React, { useState } from "react";
import { Button, Input, Tooltip, Typography, message } from "antd";
import {
  CopyOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import { formatBezierCurveInputs } from "../../utils/bezierCurve";
import { generateMCFunctions } from "./generateMCFunctions";

type InputChange =
  | {
      key: "origin";
      value: string;
    }
  | { key: "destination"; value: string }
  | { key: "checkpoint"; value: string }
  | { key: "facing"; value: string }
  | { key: "command"; value: string }
  | { key: "startingFrame"; value: string }
  | { key: "points"; value: string };

export default function Normal() {
  const [inputs, setInputs] = useState({
    origin: "",
    destination: "",
    checkpoint: "",
    facing: "",
    points: 10,
  });
  const [output, setOutput] = useState("");

  const handleInputChange = (input: InputChange) => {
    const { key, value } = input;
    setInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleGenerate = () => {
    const { origin, destination, checkpoint, points, facing } = inputs;
    const DEFAULT_COMMAND =
      "execute if score @s cinematic matches %index run tp @s %coords facing %facing_coords";
    const END_COMMAND = `\nexecute if score @s cinematic matches ${
      points * 20 - 1
    } run function cinematics/shot_1/end`;
    if (!origin || !destination || !checkpoint || !facing) {
      message.error("Complete all required fields");
      return;
    }
    const functionOutput =
      formatBezierCurveInputs({
        ...inputs,
        points: points * 20,
        command: DEFAULT_COMMAND,
        startingFrame: "0",
      }) + END_COMMAND;
    setOutput(functionOutput);
    generateMCFunctions(functionOutput);
  };

  return (
    <>
      <div className="container column justify-start align-start full-width gap-8">
        <div
          className="container justify-start align-start full-width gap-8"
          style={{ flex: 1 }}
        >
          <Input
            addonBefore="Origin *"
            placeholder="x y z"
            size="large"
            value={inputs.origin}
            onChange={(e) =>
              handleInputChange({ key: "origin", value: e.target.value })
            }
            suffix={
              <Tooltip title="The origin coordinates from where the player will start the cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Destination *"
            placeholder="x y z"
            size="large"
            value={inputs.destination}
            onChange={(e) =>
              handleInputChange({
                key: "destination",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The destination coordinates where the player will end the cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Control point *"
            placeholder="x y z"
            size="large"
            value={inputs.checkpoint}
            onChange={(e) =>
              handleInputChange({
                key: "checkpoint",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip
                title={
                  <span>
                    The coordinates to where the curve will be made. See how a{" "}
                    <Link
                      href="https://en.wikipedia.org/wiki/Bézier_curve"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bézier curve
                    </Link>{" "}
                    works to understand. 'x y z' without any commas.
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Facing *"
            placeholder="x y z"
            size="large"
            value={inputs.facing}
            onChange={(e) =>
              handleInputChange({ key: "facing", value: e.target.value })
            }
            suffix={
              <Tooltip title="The coordinates where the player will face during the entire cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Duration"
            placeholder="x"
            size="large"
            value={inputs.points}
            onChange={(e) =>
              handleInputChange({
                key: "points",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The duration in seconds of the cinematic.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Button
            size="large"
            type="primary"
            onClick={handleGenerate}
            icon={<DownloadOutlined />}
          >
            Generate functions
          </Button>
        </div>
      </div>
      {output && (
        <Typography>
          <Typography.Paragraph style={{ position: "relative" }}>
            <pre className="scroll">{output}</pre>
            <Tooltip title="Copy to clipboard">
              <Button
                type="primary"
                size="large"
                shape="circle"
                style={{ position: "absolute", top: 16, right: 24 }}
                icon={<CopyOutlined />}
                onClick={() => navigator.clipboard.writeText(output)}
              />
            </Tooltip>
          </Typography.Paragraph>
        </Typography>
      )}
    </>
  );
}
