import {
  AddonDefinition,
  AddonManifest,
  PackError,
  PackType,
  WorldManifest,
} from "../interfaces/packCommon";
import {
  addonDefinition,
  addonManifest,
  worldManifest,
  MIN_ENGINE_VERSION,
} from "./manifestTemplate";
import { v4 as uuidv4, validate } from "uuid";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { message } from "antd";
import {
  convertJpgToPng,
  decodeImage,
  generateOrValidateUuidV4,
  removeSpaces,
  resizeImage,
} from "./common";
import { Dispatch } from "redux";
import {
  Screenshot,
  ScreenshotFile,
  UpdateWorldPack,
  WorldPack,
} from "../interfaces/worldPacker";
import worldPackObject from "./worldPackObject";
import { defaultPartnerArt } from "../settings/settings";
import { setWorldPackState } from "../store/slices/worldPackSlice";
import { setPackErrorsState } from "../store/slices/packsErrorsSlice";
import { ResourcePack } from "../interfaces/resourcePacker";

export function newWorldPack(): WorldPack {
  const pack = { ...worldPackObject };
  pack.uuid = uuidv4();
  pack.uuidModules = uuidv4();
  return pack;
}

export function updateWorldPack({
  pack,
  key,
  value,
  dispatch,
}: UpdateWorldPack) {
  const packAux: WorldPack = { ...pack, [key]: value };
  dispatch(setWorldPackState(packAux));
}

export function fileToScreenshot(files: ScreenshotFile[]): Screenshot[] {
  return files.map((file, index) => ({
    uid: file.uid,
    name: `MarketingScreenshot_${index}`,
    url: file.url ? file.url : "",
  }));
}

export function screenshotToFile(screenshots: Screenshot[]): ScreenshotFile[] {
  return screenshots.map((screenshot) => ({
    uid: screenshot.uid,
    url: screenshot.url,
    name: screenshot.name,
  }));
}

export function removeScreenshot(
  screenshots: ScreenshotFile[],
  uid: string
): ScreenshotFile[] {
  const shAux: ScreenshotFile[] = [...screenshots];
  shAux.splice(
    shAux.findIndex((sh) => sh.uid === uid),
    1
  );
  console.log(uid);
  console.log(shAux);
  return shAux;
}

export function createWorldTextsFile(pack: WorldPack | ResourcePack): string {
  const lines: string[] = [];
  lines.push(`pack.name=${pack.title}`);
  lines.push(`pack.description=${pack.description}`);
  return lines.join("\n");
}

export function createWorldPackManifest(
  pack: WorldPack,
  baseGameVersion: number[]
): WorldManifest {
  const manifestTemplate = { ...worldManifest };
  manifestTemplate.header.name = "pack.name";
  manifestTemplate.header.uuid = generateOrValidateUuidV4(pack.uuid);
  manifestTemplate.header.version = [...pack.version];
  manifestTemplate.modules[0].uuid = generateOrValidateUuidV4(pack.uuidModules);
  manifestTemplate.modules[0].type = "world_template";
  manifestTemplate.modules[0].version = [...pack.version];
  manifestTemplate.metadata.authors = [pack.author];
  if (!pack.title) {
    message.warning("The pack does not have a title.", 5);
  }
  return manifestTemplate;
}

export function createAddonManifest(
  pack: WorldPack | ResourcePack,
  type: PackType,
  uuid: string,
  minEngineVersion: number[],
  dependencieUuid?: string
): AddonManifest {
  const manifestTemplate = { ...addonManifest };
  manifestTemplate.header.uuid = generateOrValidateUuidV4(uuid);
  manifestTemplate.header.version = [...pack.version];
  manifestTemplate.header.min_engine_version = [...minEngineVersion];
  manifestTemplate.modules[0].uuid = uuidv4();
  manifestTemplate.modules[0].type = type;
  manifestTemplate.modules[0].version = [...pack.version];

  if (dependencieUuid) {
    manifestTemplate.dependencies = [
      { version: [...pack.version], uuid: dependencieUuid },
    ];
  }

  if (!pack.title) {
    message.warning("The pack does not have a title.", 5);
  }
  return manifestTemplate;
}

export function createAddonDefinition(
  pack: WorldPack,
  uuid: string
): AddonDefinition[] {
  const addonDef = { ...addonDefinition };
  addonDef.pack_id = uuid;
  addonDef.version = [...pack.version];
  return [addonDef];
}

export async function createWorldPackZip(pack: WorldPack): Promise<boolean> {
  const jszip = new JSZip();
  const storeArtFolder = jszip.folder("Store Art");
  const marketingArtFolder = jszip.folder("Marketing Art");
  const contentFolder = jszip.folder("Content");
  const worldPackFolder = contentFolder?.folder("world_template");

  // cargar mundo

  if (pack.world) {
    const worldFile = await getWorldFiles(pack.world);

    for (const [relativePath, file] of Object.entries(worldFile.files)) {
      const blob = await worldFile?.file(relativePath)?.async("blob");
      if (worldPackFolder && blob) {
        worldPackFolder.file(relativePath, blob);
      }
    }
  }

  // cargar screenshots
  const screenshots = pack.screenshots.map(async (screenshot, index) => {
    if (screenshot.url) {
      const imageBlob = await decodeImage(screenshot.url);
      marketingArtFolder?.file(
        `${removeSpaces(pack.title)}_MarketingScreenshot_${index}.jpg`,
        imageBlob
      );

      const thumbnailBlob = resizeImage(imageBlob, 800, 450);
      storeArtFolder?.file(
        `${removeSpaces(pack.title).toLowerCase()}_screenshot_${index}.jpg`,
        thumbnailBlob
      );
    }
  });

  // cargar partner art
  const partnerArt = pack.partnerArt.url || defaultPartnerArt;

  const partnerArtBlob = await decodeImage(partnerArt);
  marketingArtFolder?.file(
    `${removeSpaces(pack.title)}_PartnerArt.jpg`,
    partnerArtBlob
  );

  // cargar keyart
  const keyArtBlob = pack.keyart.url
    ? await decodeImage(pack.keyart.url)
    : null;

  if (keyArtBlob) {
    marketingArtFolder?.file(
      `${removeSpaces(pack.title)}_MarketingKeyArt.jpg`,
      keyArtBlob
    );

    const thumbnailBlob = resizeImage(keyArtBlob, 800, 450);
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_Thumbnail_0.jpg`,
      thumbnailBlob
    );

    worldPackFolder?.file("world_icon.jpeg", thumbnailBlob);
  } else {
    message.warning("Your package was packed without a keyart", 5);
  }

  // cargar panorama
  const panoramaBlob = pack.panorama.url
    ? await decodeImage(pack.panorama.url)
    : null;

  if (panoramaBlob) {
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_panorama_0.jpg`,
      panoramaBlob
    );
  } else {
    message.warning("Your package was packed without a panorama", 5);
  }

  // crear texts folder
  const textsFolder = worldPackFolder?.folder("texts");

  const textsFile = createWorldTextsFile(pack);
  textsFolder?.file("en_US.lang", textsFile);
  textsFolder?.file("languages.json", JSON.stringify(["en_US"]));

  // editar level name
  worldPackFolder?.file("levelname.txt", pack.title);

  // cargar packIcon
  const packIconBlob = pack.packIcon.url
    ? await decodeImage(pack.packIcon.url)
    : null;

  if (packIconBlob) {
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_packicon_0.jpg`,
      packIconBlob
    );
  } else {
    message.warning("Your package was packed without a pack icon", 5);
  }

  // crear world manifest
  if (worldPackFolder) {
    const baseGameVersion = await getBaseGameVersion(
      worldPackFolder,
      "manifest.json"
    );
    const worldManifest = createWorldPackManifest(
      pack,
      baseGameVersion || MIN_ENGINE_VERSION
    );
    if (worldManifest) {
      worldPackFolder?.file(
        "manifest.json",
        JSON.stringify(worldManifest, null, 2)
      );
    }
  }

  // crear behavior y resource manifest
  const behaviorUuid = uuidv4();
  const resourceUuid = uuidv4();
  const packIconPNG = pack.packIcon.url
    ? await convertJpgToPng(pack.packIcon.url)
    : null;

  if (pack.behaviorPack) {
    const behaviorDefinition = createAddonDefinition(pack, behaviorUuid);
    worldPackFolder?.file(
      "world_behavior_packs.json",
      JSON.stringify(behaviorDefinition, null, 2)
    );
    if (worldPackFolder) {
      const min_engine_version = await getMinEngineVersion(
        worldPackFolder,
        "behavior_packs/BP/manifest.json"
      );
      const bpManifest = createAddonManifest(
        pack,
        "data",
        behaviorUuid,
        min_engine_version || MIN_ENGINE_VERSION,
        resourceUuid
      );
      worldPackFolder.file(
        "behavior_packs/BP/manifest.json",
        JSON.stringify(bpManifest, null, 2)
      );
      if (packIconPNG) {
        const packIconSmall = resizeImage(packIconPNG, 128, 128);
        worldPackFolder.file("behavior_packs/BP/pack_icon.png", packIconSmall);
      }
      // crear texts folder
      const textsFolder = worldPackFolder?.folder("behavior_packs/BP/texts");
      const textsFile = createWorldTextsFile(pack);
      textsFolder?.file("en_US.lang", textsFile);
      textsFolder?.file("languages.json", JSON.stringify(["en_US"]));
    }
  }

  // eliminar archivos innecesarios
  worldPackFolder?.remove("world_behavior_pack_history.json");
  worldPackFolder?.remove("world_resource_pack_history.json");
  worldPackFolder?.remove("level.dat_old");
  worldPackFolder?.remove(".git");
  worldPackFolder?.remove(".gitignore");

  if (pack.resourcePack) {
    const resourceDefinition = createAddonDefinition(pack, resourceUuid);
    worldPackFolder?.file(
      "world_resource_packs.json",
      JSON.stringify(resourceDefinition, null, 2)
    );
    if (worldPackFolder) {
      const min_engine_version = await getMinEngineVersion(
        worldPackFolder,
        "resource_packs/RP/manifest.json"
      );
      const rpManifest = createAddonManifest(
        pack,
        "resources",
        resourceUuid,
        min_engine_version || MIN_ENGINE_VERSION
      );
      worldPackFolder.file(
        "resource_packs/RP/manifest.json",
        JSON.stringify(rpManifest, null, 2)
      );
      if (packIconPNG) {
        const packIconSmall = resizeImage(packIconPNG, 128, 128);
        worldPackFolder.file("resource_packs/RP/pack_icon.png", packIconSmall);
      }
      // crear texts folder
      const textsFolder = worldPackFolder?.folder("resource_packs/RP/texts");
      const textsFile = createWorldTextsFile(pack);
      textsFolder?.file("en_US.lang", textsFile);
      textsFolder?.file("languages.json", JSON.stringify(["en_US"]));
    }
  }

  /*
  if (worldPackFolder) {
    await renameFolderInPath(
      worldPackFolder,
      "behavior_packs/BP",
      `behavior_packs/${pack.acronym}_BP`
    );
    await renameFolderInPath(
      worldPackFolder,
      "resource_packs/RP",
      `resource_packs/${pack.acronym}_RP`
    );
  }
  */

  // Esperar a que se resuelvan todas las promesas de carga de imágenes
  await Promise.all(screenshots);

  return jszip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${pack.title}.zip`);
    message.success(
      `Your package was successfully downloaded with the name "${
        pack.title || "zip"
      }.zip"`,
      5
    );
    return true;
  });
}

export async function getWorldFiles(worldFile: File): Promise<JSZip> {
  try {
    const zip = await JSZip.loadAsync(worldFile);

    // Generar un nuevo Blob con las modificaciones
    // const newBlob = await zip.generateAsync({ type: "blob" });

    return zip;
  } catch (error) {
    console.error("Error al cargar el archivo .mcworld:", error);
    throw error; // Puedes manejar el error según tus necesidades
  }
}

export function checkWorldPack(pack: WorldPack, dispatch: Dispatch): boolean {
  const errors: PackError[] = [];

  // if (!pack.keyart.url) {
  //   errors.push("You did not add the pack keyart");
  // }
  /*
  if (!pack.acronym) {
    errors.push("The package has no acronym");
  }
  */
  if (!pack.world) {
    errors.push("The package has no world file");
  }
  if (!pack.description) {
    errors.push("The package has no description");
  }
  if (!pack.title) {
    errors.push("The package has no title");
  }
  if (!validate(pack.uuid)) {
    errors.push("The 'Pack UUID' is invalid");
  }
  if (!validate(pack.uuidModules)) {
    errors.push("The 'Modules UUID' is not valid");
  }
  dispatch(setPackErrorsState(errors));
  return errors.length === 0;
}

/*
async function renameFolderInPath(
  zip: JSZip,
  folderName: string,
  newFolderName: string
): Promise<JSZip> {
  try {
    const promises = [];
    console.log(folderName);
    const transfers = pack.screenshots.map(async (screenshot, index) => {
      if (screenshot.url) {
        const imageBlob = await decodeImage(screenshot.url);
        marketingArtFolder?.file(
          `${removeSpaces(pack.title)}_MarketingScreenshot_${index}.jpg`,
          imageBlob
        );
  
        const thumbnailBlob = resizeImage(imageBlob, 800, 450);
        storeArtFolder?.file(
          `${removeSpaces(pack.title).toLowerCase()}_screenshot_${index}.jpg`,
          thumbnailBlob
        );
      }
    });
    if (folderName !== "") {
      // Crear una nueva carpeta con el nuevo nombre
      const prevFolder = zip.folder(folderName);
      const newFolder = zip.folder(newFolderName);
      if (prevFolder) {
        prevFolder.forEach(async function (relativePath, file) {
          promises.push(prevFolder?.file(relativePath)?.async("blob"));
          const blob = await prevFolder?.file(relativePath)?.async("blob");
          if (newFolder && blob) {
            newFolder.file(relativePath, blob);
            console.log(newFolder);
          }
        });
      }
      // Eliminar la carpeta antigua
      zip.remove(folderName);
    }

    return zip;
  } catch (error) {
    console.error("Error al cambiar el nombre de la carpeta:", error);
    throw error;
  }
}
*/
export async function getMinEngineVersion(
  zip: JSZip,
  path: string
): Promise<number[] | undefined> {
  try {
    // Obtener el contenido del archivo manifest.json
    const manifestContent = await zip.file(path)?.async("text");

    if (manifestContent) {
      // Parsear el contenido como JSON
      const manifest: AddonManifest = JSON.parse(manifestContent);

      // Acceder al valor de min_engine_version
      const minEngineVersion = manifest?.header?.min_engine_version;

      return minEngineVersion;
    }

    return undefined;
  } catch (error) {
    console.error("Error al obtener min_engine_version:", error);
    throw error;
  }
}

async function getBaseGameVersion(
  zip: JSZip,
  path: string
): Promise<number[] | undefined> {
  try {
    // Obtener el contenido del archivo manifest.json
    const manifestContent = await zip.file(path)?.async("text");

    if (manifestContent) {
      // Parsear el contenido como JSON
      const manifest: WorldManifest = JSON.parse(manifestContent);

      // Acceder al valor de base_game_version
      const baseGameVersion = manifest?.header?.base_game_version;

      return baseGameVersion;
    }

    return undefined;
  } catch (error) {
    console.error("Error al obtener base_game_version:", error);
    throw error;
  }
}
