import { v4 as uuidv4, validate } from "uuid";

import { ResourcePack, UpdateResourcePack } from "../interfaces/resourcePacker";
import resourcePackObject from "./resourcePackObject";
import { setResourcePackState } from "../store/slices/resourcePackSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { PackError } from "../interfaces/packCommon";
import { setPackErrorsState } from "../store/slices/packsErrorsSlice";
import JSZip from "jszip";
import {
  convertJpgToPng,
  decodeImage,
  removeSpaces,
  resizeImage,
} from "./common";
import { message } from "antd";
import {
  createAddonManifest,
  createWorldTextsFile,
  getMinEngineVersion,
} from "./worldPacker";
import { MIN_ENGINE_VERSION } from "./manifestTemplate";
import { saveAs } from "file-saver";
import { defaultPartnerArt } from "../settings/settings";

export function newResourcePack(): ResourcePack {
  const pack = { ...resourcePackObject };
  pack.uuid = uuidv4();
  pack.uuidModules = uuidv4();
  return pack;
}

export function updateResourcePack({
  pack,
  key,
  value,
  dispatch,
}: UpdateResourcePack) {
  const packAux: ResourcePack = { ...pack, [key]: value };
  dispatch(setResourcePackState(packAux));
}

export function checkResourcePack(
  pack: ResourcePack,
  dispatch: Dispatch
): boolean {
  const errors: PackError[] = [];

  // if (!pack.keyart.url) {
  //   errors.push("You did not add the pack keyart");
  // }
  /*
  if (!pack.acronym) {
    errors.push("The package has no acronym");
  }
  */
  if (pack.type === "resource" && !pack.resource) {
    errors.push("The package has no resource file");
  }
  if (pack.type === "behavior" && !pack.behavior) {
    errors.push("The package has no behavior file");
  }
  if (!pack.description) {
    errors.push("The package has no description");
  }
  if (!pack.title) {
    errors.push("The package has no title");
  }
  if (!validate(pack.uuid)) {
    errors.push("The 'Pack UUID' is invalid");
  }
  if (!validate(pack.uuidModules)) {
    errors.push("The 'Modules UUID' is not valid");
  }
  if (!pack.type) {
    errors.push("You have not selected the pack type.");
  }
  dispatch(setPackErrorsState(errors));
  return errors.length === 0;
}

export async function createResourcePackZip(
  pack: ResourcePack
): Promise<boolean> {
  const jszip = new JSZip();
  const storeArtFolder = jszip.folder("Store Art");
  const marketingArtFolder = jszip.folder("Marketing Art");
  const contentFolder = jszip.folder("Content");
  let resourceFolder: JSZip | null = null;
  let behaviorFolder: JSZip | null = null;

  if (pack.resource) {
    resourceFolder = contentFolder?.folder("resource_pack/RP") || null;
    Object.keys(pack.resource.files).forEach(async (filePath) => {
      const fileEntry = pack.resource?.file(filePath);

      if (fileEntry) {
        const fileBlob = await fileEntry.async("blob");
        resourceFolder?.file(filePath, fileBlob);
      }
    });
  }

  if (pack.type === "behavior" && pack.behavior) {
    behaviorFolder = contentFolder?.folder("behavior_pack/BP") || null;
    Object.keys(pack.behavior.files).forEach(async (filePath) => {
      const fileEntry = pack.behavior?.file(filePath);

      if (fileEntry) {
        const fileBlob = await fileEntry.async("blob");
        behaviorFolder?.file(filePath, fileBlob);
      }
    });
  }

  // cargar screenshots
  const screenshots = pack.screenshots.map(async (screenshot, index) => {
    if (screenshot.url) {
      const imageBlob = await decodeImage(screenshot.url);
      marketingArtFolder?.file(
        `${removeSpaces(pack.title)}_MarketingScreenshot_${index}.jpg`,
        imageBlob
      );

      const thumbnailBlob = resizeImage(imageBlob, 800, 450);
      storeArtFolder?.file(
        `${removeSpaces(pack.title).toLowerCase()}_screenshot_${index}.jpg`,
        thumbnailBlob
      );
    }
  });

  // cargar partner art
  const partnerArt = pack.partnerArt.url || defaultPartnerArt;

  const partnerArtBlob = await decodeImage(partnerArt);
  marketingArtFolder?.file(
    `${removeSpaces(pack.title)}_PartnerArt.jpg`,
    partnerArtBlob
  );

  // cargar keyart
  const keyArtBlob = pack.keyart.url
    ? await decodeImage(pack.keyart.url)
    : null;

  if (keyArtBlob) {
    marketingArtFolder?.file(
      `${removeSpaces(pack.title)}_MarketingKeyArt.jpg`,
      keyArtBlob
    );

    const thumbnailBlob = resizeImage(keyArtBlob, 800, 450);
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_Thumbnail_0.jpg`,
      thumbnailBlob
    );
  } else {
    message.warning("Your package was packed without a keyart", 5);
  }

  // cargar panorama
  const panoramaBlob = pack.panorama.url
    ? await decodeImage(pack.panorama.url)
    : null;

  if (panoramaBlob) {
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_panorama_0.jpg`,
      panoramaBlob
    );
  } else {
    message.warning("Your package was packed without a panorama", 5);
  }

  // cargar packIcon
  const packIconBlob = pack.packIcon.url
    ? await decodeImage(pack.packIcon.url)
    : null;

  if (packIconBlob) {
    storeArtFolder?.file(
      `${removeSpaces(pack.title).toLowerCase()}_packicon_0.jpg`,
      packIconBlob
    );
  } else {
    message.warning("Your package was packed without a pack icon", 5);
  }

  // crear resource manifest
  const packIconPNG = pack.packIcon.url
    ? await convertJpgToPng(pack.packIcon.url)
    : null;

  console.log(pack.uuid);
  if (resourceFolder) {
    // crear texts folder
    const textsFolder = resourceFolder?.folder("texts");

    const textsFile = createWorldTextsFile(pack);
    textsFolder?.file("en_US.lang", textsFile);
    textsFolder?.file("languages.json", JSON.stringify(["en_US"]));

    const min_engine_version = await getMinEngineVersion(
      resourceFolder,
      "manifest.json"
    );
    const rpManifest = createAddonManifest(
      pack,
      "resources",
      pack.uuid,
      min_engine_version || MIN_ENGINE_VERSION
    );
    resourceFolder.file("manifest.json", JSON.stringify(rpManifest, null, 2));

    if (packIconPNG) {
      const packIconSmall = resizeImage(packIconPNG, 128, 128);
      resourceFolder.file("pack_icon.png", packIconSmall);
    }
  }

  if (behaviorFolder) {
    // crear texts folder
    const textsFolder = behaviorFolder?.folder("texts");

    const textsFile = createWorldTextsFile(pack);
    textsFolder?.file("en_US.lang", textsFile);
    textsFolder?.file("languages.json", JSON.stringify(["en_US"]));

    const min_engine_version = await getMinEngineVersion(
      behaviorFolder,
      "manifest.json"
    );
    const bpManifest = createAddonManifest(
      pack,
      "data",
      uuidv4(),
      min_engine_version || MIN_ENGINE_VERSION,
      pack.uuid
    );
    behaviorFolder.file("manifest.json", JSON.stringify(bpManifest, null, 2));

    if (packIconPNG) {
      const packIconSmall = resizeImage(packIconPNG, 128, 128);
      behaviorFolder.file("pack_icon.png", packIconSmall);
    }
  }

  // Esperar a que se resuelvan todas las promesas de carga de imágenes
  await Promise.all(screenshots);

  return jszip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${pack.title}.zip`);
    message.success(
      `Your package was successfully downloaded with the name "${
        pack.title || "zip"
      }.zip"`,
      5
    );
    return true;
  });
}

export async function getResourceFiles(resourceFile: File): Promise<JSZip> {
  try {
    const zip = await JSZip.loadAsync(resourceFile);

    return zip;
  } catch (error) {
    console.error("Error al cargar el archivo:", error);
    throw error;
  }
}
