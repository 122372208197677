import React, { useEffect, useState } from "react";
import { Image, Typography } from "antd";
import { updateWorldPack } from "../../utils/worldPacker";
import { useDispatch, useSelector } from "react-redux";
import { KeyartFile } from "../../interfaces/packCommon";
import ImageButton from "./ImageButton";
import { v4 as uuidv4 } from "uuid";
import {
  defaultPartnerArt,
  defaultPartnerArtList,
} from "../../settings/settings";
import UploadImage from "./UploadImage";
import { SkinPack } from "../../interfaces/skinPacker";
import { updateSkinPack } from "../../utils/skinPacker";
import { WorldPack } from "../../interfaces/worldPacker";
import { RootState } from "../../store";
import { updateResourcePack } from "../../utils/resourcePacker";
import { ResourcePack } from "../../interfaces/resourcePacker";
const { Text } = Typography;

interface Props {
  packType: "worldPack" | "skinPack" | "resourcePack";
}
export default function PartnerArt({ packType }: Props) {
  const dispatch = useDispatch();
  const pack = useSelector((state: RootState) => state[packType]);
  const [fileList, setFileList] = useState<KeyartFile[]>([pack.partnerArt]);

  useEffect(() => {
    if (packType === "worldPack") {
      updateWorldPack({
        dispatch,
        key: "partnerArt",
        value: { ...fileList[0] },
        pack: pack as WorldPack,
      });
    } else if (packType === "skinPack") {
      updateSkinPack({
        dispatch,
        key: "partnerArt",
        value: { ...fileList[0] },
        pack: pack as SkinPack,
      });
    } else if (packType === "resourcePack") {
      updateResourcePack({
        dispatch,
        key: "partnerArt",
        value: { ...fileList[0] },
        pack: pack as ResourcePack,
      });
    }
  }, [fileList]);

  return (
    <div className="container justify-start align-start full-width gap-16 ">
      <div className="container justify-start align-start full-width gap-16">
        <div
          style={{ flex: 1 }}
          className="container justify-start align-start full-width gap-8 column"
        >
          <div className="full-width">
            <UploadImage
              type="jpeg"
              defaultFiles={[pack.partnerArt]}
              upload={(url, uid) =>
                setFileList([
                  {
                    uid: uid,
                    url: url || "",
                    name: "",
                  },
                ])
              }
              title="Click or drag file to this area to upload"
              description="Upload the partner art here. jpg (1920x1080) files only"
            />
          </div>
          <div className="container column gap-8">
            <Text type="secondary" strong>
              Default Partner Art
            </Text>
            <div className="container justify-start align-start full-width gap-8 wrap">
              {defaultPartnerArtList.map((img, index) => (
                <ImageButton
                  image={img}
                  onClick={(image) => {
                    if (packType === "worldPack") {
                      updateWorldPack({
                        pack: pack as WorldPack,
                        key: "partnerArt",
                        value: { name: "", uid: uuidv4(), url: image },
                        dispatch,
                      });
                    } else if (packType === "skinPack") {
                      updateSkinPack({
                        pack: pack as SkinPack,
                        key: "partnerArt",
                        value: { name: "", uid: uuidv4(), url: image },
                        dispatch,
                      });
                    } else if (packType === "resourcePack") {
                      updateResourcePack({
                        pack: pack as ResourcePack,
                        key: "partnerArt",
                        value: { name: "", uid: uuidv4(), url: image },
                        dispatch,
                      });
                    }
                  }}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
        {fileList.length !== 0 && (
          <Image
            height={450}
            src={pack.partnerArt.url || defaultPartnerArt}
            style={{ flex: 3, borderRadius: 8 }}
            placeholder={false}
          />
        )}
      </div>
    </div>
  );
}
