import { useAppDispatch, useAppSelector } from "./store";
import { ResourcePack } from "../interfaces/resourcePacker";
import { setResourcePackState } from "../store/slices/resourcePackSlice";

export default function useResourcePack() {
  const resourcePack = useAppSelector((state) => state.resourcePack);
  const dispatch = useAppDispatch();

  const setResourcePack = (pack: ResourcePack) => {
    dispatch(setResourcePackState(pack));
  };

  return {
    resourcePack,
    setResourcePack,
  };
}
