import { useAppDispatch, useAppSelector } from "./store";
import { User } from "../interfaces/common";
import { setUser } from "../store/slices/userSlice";

export default function useUser() {
  const currentUser = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const setCurrentUser = (user: User) => {
    dispatch(setUser(user));
  };

  return {
    currentUser,
    setCurrentUser,
  };
}
