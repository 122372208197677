import { RcFile } from "antd/es/upload";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "./firebase";
import { COLLECTIONS } from "./firestore";
import {
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  BlockList,
  TPCreatorSlots,
  TPTextures,
} from "../interfaces/texturePackCreator";
import blocks_list from "../assets/blocks_list.json";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export async function uploadTexture(
  file: RcFile,
  filePath: string,
  packID: string
): Promise<string> {
  const imageRef = ref(storage, `tools/tp-creator/${packID}/${filePath}`);
  return await uploadBytes(imageRef, file)
    .then(async () => {
      return await getDownloadURL(imageRef)
        .then((url) => {
          return Promise.resolve(url);
        })
        .catch((error) => Promise.reject(error));
    })
    .catch((error) => Promise.reject(error));
}

export async function updateTextureDoc(
  userUID: string,
  blockID: string,
  textureURL: string,
  packID: string,
  slot: string
): Promise<string> {
  const docRef = doc(db, COLLECTIONS.TPCREATOR, userUID);
  const document = await getDoc(docRef);
  if (document.exists()) {
    return await updateDoc(docRef, {
      [`slots.${slot}.textures.${blockID}`]: textureURL,
      [`slots.${slot}.packID`]: packID,
    })
      .then(() => Promise.resolve(""))
      .catch((error) => Promise.reject(error));
  } else
    return await setDoc(docRef, {
      slots: { [slot]: { textures: { [blockID]: textureURL }, packID } },
    })
      .then(() => Promise.resolve(""))
      .catch((error) => Promise.reject(error));
}

export async function removeTexture(
  userUID: string,
  blockID: string,
  slot: string,
  packID: string
): Promise<string> {
  const docRef = doc(db, COLLECTIONS.TPCREATOR, userUID);
  const blockList = JSON.parse(JSON.stringify(blocks_list)) as BlockList;
  const filePath = blockList?.[blockID].filePath ?? "";
  if (filePath)
    deleteStoragePath(`tools/tp-creator/${packID}/${filePath}`).catch((error) =>
      Promise.reject(error)
    );
  const document = await getDoc(docRef);
  if (document.exists()) {
    return await updateDoc(docRef, {
      [`slots.${slot}.textures.${blockID}`]: deleteField(),
    })
      .then(() => Promise.resolve(""))
      .catch((error) => Promise.reject(error));
  } else return Promise.resolve("");
}

export async function getTPSlots(userUID: string) {
  try {
    const docRef = doc(db, COLLECTIONS.TPCREATOR, userUID);
    const userConfigRef = doc(db, COLLECTIONS.USER_CONFIG, userUID);
    const slots = await getDoc(docRef).then((result) => {
      if (result.exists()) {
        return result.data().slots as TPCreatorSlots;
      }
      return {} as TPCreatorSlots;
    });
    const amountSlots = await getDoc(userConfigRef).then((result) => {
      if (result.exists()) {
        return result.data().amountSlots as number;
      }
      return 3;
    });
    return { slots, amountSlots };
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function removeSlotFromDoc(
  userUID: string,
  slot: number,
  packID: string,
  textures: TPTextures
): Promise<string> {
  const docRef = doc(db, COLLECTIONS.TPCREATOR, userUID);
  const blocksList: BlockList = JSON.parse(JSON.stringify(blocks_list));
  const blocksPathList = Object.entries(textures).map(
    ([blockID, blockTexture]) => {
      const filePath = blocksList?.[blockID].filePath ?? "";
      return filePath;
    }
  );
  Promise.all(
    blocksPathList.map((filePath) => {
      if (filePath) {
        return deleteStoragePath(`tools/tp-creator/${packID}/${filePath}`)
          .then()
          .catch((error) => Promise.reject(error));
      }
      return null;
    })
  )
    .then()
    .catch((error) => Promise.reject(error));

  const document = await getDoc(docRef);
  if (document.exists()) {
    return await updateDoc(docRef, {
      [`slots.${slot}`]: deleteField(),
    })
      .then(() => Promise.resolve(""))
      .catch((error) => Promise.reject(error));
  } else return Promise.resolve("");
}

export async function deleteStoragePath(path: string) {
  const pathRef = ref(storage, path);

  return await deleteObject(pathRef)
    .then(() => {
      return Promise.resolve("");
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function downloadFolderAsZip(packID: string) {
  try {
    const jszip = new JSZip();
    const blocksFolder = jszip.folder("blocks");
    const blocksRef = ref(storage, `tools/tp-creator/${packID}/`);
    const deepslateRef = ref(storage, `tools/tp-creator/${packID}/deepslate`);
    const candlesRef = ref(storage, `tools/tp-creator/${packID}/candles`);
    const hugeFungusRef = ref(
      storage,
      `tools/tp-creator/${packID}/huge_fungus`
    );

    const [
      blocksFolderList,
      deepslateFolderList,
      candlesFolderList,
      hugeFungusFolderList,
    ] = await Promise.all([
      listAll(blocksRef),
      listAll(deepslateRef),
      listAll(candlesRef),
      listAll(hugeFungusRef),
    ]);

    let deepslateFolder: JSZip | null;
    let candlesFolder: JSZip | null;
    let hugeFungusFolder: JSZip | null;

    if (blocksFolderList.items.length !== 0) {
      const promises = blocksFolderList.items
        .map(async (item) => {
          const fileRef = ref(storage, item.fullPath);
          const fileBlob = await getDownloadURL(fileRef).then((url) => {
            return fetch(url).then((response) => response.blob());
          });
          if (blocksFolder) blocksFolder.file(item.name, fileBlob);
        })
        .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
      await promises;
    }
    if (deepslateFolderList.items.length !== 0) {
      if (!blocksFolder) return;
      deepslateFolder = blocksFolder.folder("deepslate");
      const promises = deepslateFolderList.items
        .map(async (item) => {
          const fileRef = ref(storage, item.fullPath);
          const fileBlob = await getDownloadURL(fileRef).then((url) => {
            return fetch(url).then((response) => response.blob());
          });
          if (deepslateFolder) deepslateFolder.file(item.name, fileBlob);
        })
        .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
      await promises;
    }
    if (candlesFolderList.items.length !== 0) {
      if (!blocksFolder) return;
      candlesFolder = blocksFolder.folder("candles");
      const promises = candlesFolderList.items
        .map(async (item) => {
          const fileRef = ref(storage, item.fullPath);
          const fileBlob = await getDownloadURL(fileRef).then((url) => {
            return fetch(url).then((response) => response.blob());
          });
          if (candlesFolder) candlesFolder.file(item.name, fileBlob);
        })
        .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
      await promises;
    }
    if (hugeFungusFolderList.items.length !== 0) {
      if (!blocksFolder) return;
      hugeFungusFolder = blocksFolder.folder("huge_fungus");
      const promises = hugeFungusFolderList.items
        .map(async (item) => {
          const fileRef = ref(storage, item.fullPath);
          const fileBlob = await getDownloadURL(fileRef).then((url) => {
            return fetch(url).then((response) => response.blob());
          });
          if (hugeFungusFolder) hugeFungusFolder.file(item.name, fileBlob);
        })
        .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
      await promises;
    }
    const blob = await jszip.generateAsync({ type: "blob" });
    saveAs(blob, `Texture pack.zip`);
    return Promise.resolve("");
  } catch (error) {
    return Promise.reject(error);
  }
}
