import React from "react";
import { SkinPackFB } from "../../../interfaces/skinPacker";
import { Badge, Card, Tag, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getColor } from "../../../utils/packTags";
import { TagsOutlined, SkinOutlined } from "@ant-design/icons";
import STATUS from "../../../utils/status";
import { defaultThumbnail } from "../../../settings/settings";

interface Props {
  pack: SkinPackFB;
}
export default function SkinPackCard({ pack }: Props) {
  const navigate = useNavigate();
  return (
    <Badge.Ribbon
      text={STATUS[pack.status].label}
      color={STATUS[pack.status].color}
    >
      <Tooltip title={pack.description} color="#121212">
        <Card
          bordered={false}
          style={{ width: 300, backgroundColor: "black" }}
          bodyStyle={{ padding: 14 }}
          cover={
            <img
              alt={pack.pack.title}
              src={pack.pack.keyart.url || defaultThumbnail}
            />
          }
          type="inner"
          hoverable
          onClick={() => navigate(`/packs/skin_pack?uid=${pack.pack.uuid}`)}
        >
          <Card.Meta
            title={
              <Typography.Title style={{ margin: 0 }} level={3}>
                {pack.pack.title}
              </Typography.Title>
            }
            description={
              <div className="container justify-btw align-start gap-8">
                <div
                  className="container gap-4 justify-start align-center wrap"
                  style={{ flex: 2 }}
                >
                  <TagsOutlined style={{ fontSize: 16 }} />
                  {pack.tags.length === 0 ? (
                    <Typography.Text italic type="secondary">
                      No tags
                    </Typography.Text>
                  ) : (
                    pack.tags.map((tag, index) => (
                      <Tag
                        color={getColor(tag)}
                        style={{ margin: 0, textTransform: "capitalize" }}
                        key={index}
                      >
                        {tag}
                      </Tag>
                    ))
                  )}
                </div>
                <Tooltip title="Skins" style={{ flex: 1 }}>
                  <SkinOutlined />
                  <Typography.Text type="secondary">
                    {" "}
                    {pack.pack.skins.length}
                  </Typography.Text>
                </Tooltip>
              </div>
            }
          />
        </Card>
      </Tooltip>
    </Badge.Ribbon>
  );
}
