import { configureStore, combineReducers, Middleware } from "@reduxjs/toolkit";
import skinPacksReducer from "./slices/skinPacksSlice";
import packErrorsReducer from "./slices/packsErrorsSlice";
import rotationPlayReducer from "./slices/rotationPlayReducer";
import skinPackReducer from "./slices/skinPackSlice";
import userReducer from "./slices/userSlice";
import usersReducer from "./slices/usersSlice";
import worldPackReducer from "./slices/worldPackSlice";
import resourcePackReducer from "./slices/resourcePackSlice";
import TPCreatorReducer, {
  addTexturePackID,
  removeTexturePackID,
  setSlot,
} from "./slices/tpCreatorSlice";
import {
  removeSlotFromDoc,
  removeTexture,
  updateTextureDoc,
} from "../firebase/texturePackCreator";

const syncWithDatabaseMiddleware: Middleware =
  (store) => (next) => (action) => {
    const previousState = store.getState() as RootState;
    next(action);

    if (addTexturePackID.match(action)) {
      const { blockID, textureURL, packID } = action.payload;
      const currentUser = previousState.user.uid;
      const TPCreator = previousState.TPCreator;
      const [TPSlotKey] =
        Object.entries(TPCreator.slots).find(
          ([key, value]) => value.packID === packID
        ) || [];
      if (TPSlotKey) {
        updateTextureDoc(currentUser, blockID, textureURL, packID, TPSlotKey)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      }
    }
    if (removeTexturePackID.match(action)) {
      const { blockID, packID } = action.payload;
      const currentUser = previousState.user.uid;
      const TPCreator = previousState.TPCreator;
      const TPSlotKey = Object.entries(TPCreator.slots).find(
        ([key, value]) => value.packID === packID
      )?.[0];
      if (TPSlotKey) {
        removeTexture(currentUser, blockID, TPSlotKey, packID)
          .then()
          .catch((error) => console.error(error));
      }
    }
    if (setSlot.match(action)) {
      const { slot, action: stateAction, packID } = action.payload;
      if (stateAction === "delete") {
        const currentUser = previousState.user.uid;
        const TPCreator = previousState.TPCreator;
        const TPSlotKey = Object.entries(TPCreator.slots).find(
          ([key, value]) => value.packID === packID
        )?.[0];
        if (TPSlotKey) {
          const textures = TPCreator.slots[TPSlotKey].textures;
          removeSlotFromDoc(currentUser, slot, packID, textures);
        }
      }
    }
  };

const rootReducer = combineReducers({
  skinPackCollections: skinPacksReducer,
  packErrors: packErrorsReducer,
  rotationPlay: rotationPlayReducer,
  skinPack: skinPackReducer,
  user: userReducer,
  userCollection: usersReducer,
  worldPack: worldPackReducer,
  TPCreator: TPCreatorReducer,
  resourcePack: resourcePackReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(syncWithDatabaseMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
