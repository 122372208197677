import {
  Button,
  Divider,
  Input,
  Segmented,
  Popconfirm,
  Tooltip,
  App,
} from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  DownloadOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import TexturesUpload from "./TexturesUpload";
import { downloadFolderAsZip } from "../../firebase/texturePackCreator";
import { useState } from "react";

interface Props {
  setQuery: (value: string) => void;
  setSlot: (value: number) => void;
  deleteSlot: () => void;
  query: string;
  slotAmount: number;
  defaultSlot: number;
  packID: string;
  addTextureFn: (packID: string, blockID: string, textureURL: string) => void;
}

export default function CardHeader({
  setQuery,
  query,
  setSlot,
  slotAmount,
  defaultSlot,
  deleteSlot,
  packID,
  addTextureFn,
}: Props) {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloading, setDownloading] = useState(false);

  const handleChangeSlot = (value: number) => {
    setSearchParams({ slot: String(value) });
    setSlot(value);
  };

  const handleDownload = () => {
    setDownloading(true);
    downloadFolderAsZip(packID)
      .then(() => {
        message.success("Texture pack downloaded successfully.");
      })
      .catch((error) => {
        console.error(error);
        message.error("There was an error with the download.");
      })
      .finally(() => setDownloading(false));
  };

  return (
    <div className="container full-width justify-start align-center">
      <span>Texture Pack Creator</span>
      <Divider type="vertical" style={{ height: 32 }} />
      <Segmented
        value={defaultSlot}
        size="large"
        onChange={(value) => handleChangeSlot(Number(value.toString()))}
        options={Array(slotAmount > 0 ? slotAmount : 1)
          .fill(0)
          .map((value, index) => ({
            label: `Slot ${index + 1}`,
            value: index + 1,
          }))}
      />
      <Divider type="vertical" style={{ height: 32 }} />
      <div className="container gap-8 full-width">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search by block ID..."
          size="large"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          addonAfter={
            <Button
              icon={<CloseOutlined />}
              type="text"
              shape="circle"
              onClick={() => setQuery("")}
            />
          }
        />
      </div>
      <Divider type="vertical" style={{ height: 32 }} />
      <div className="container gap-8 justify-end">
        <TexturesUpload packID={packID} addTextureFn={addTextureFn} />
        <Button
          size="large"
          type="primary"
          icon={downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          disabled={downloading}
          onClick={handleDownload}
        >
          Download texture pack
        </Button>
        <Popconfirm
          title="Reset slot"
          description="Are you sure you want to delete all the textures of this slot?"
          okText="Yes, delete"
          okType="danger"
          icon={<DeleteOutlined style={{ color: "#cc0000" }} />}
          onConfirm={() => deleteSlot()}
        >
          <Tooltip title="Reset slot">
            <Button
              icon={<DeleteOutlined />}
              danger
              size="large"
              type="primary"
            />
          </Tooltip>
        </Popconfirm>
      </div>
    </div>
  );
}
