import { Manifest, WorldManifest } from "../interfaces/packCommon";
import { AddonDefinition } from "../interfaces/packCommon";
import { AddonManifest } from "../interfaces/packCommon";

export const MIN_ENGINE_VERSION = [1, 20, 62];

export const manifest: Manifest = {
  header: { name: "", version: [1, 0, 0], uuid: "" },
  modules: [{ version: [1, 0, 0], type: "", uuid: "" }],
  format_version: 1,
};

export const worldManifest: WorldManifest = {
  header: {
    name: "pack.name",
    version: [1, 0, 0],
    uuid: "",
    description: "pack.description",
    lock_template_options: true,
    base_game_version: MIN_ENGINE_VERSION,
  },
  modules: [{ version: [1, 0, 0], type: "", uuid: "" }],
  metadata: { authors: [] },
  format_version: 2,
};

export const addonDefinition: AddonDefinition = {
  pack_id: "",
  version: [1, 0, 0],
};

export const addonManifest: AddonManifest = {
  header: {
    name: "pack.name",
    version: [1, 0, 0],
    uuid: "",
    min_engine_version: MIN_ENGINE_VERSION,
    description: "pack.description",
  },
  modules: [{ version: [1, 0, 0], type: "", uuid: "" }],

  format_version: 2,
};
