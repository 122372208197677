import { Dispatch } from "redux";
import {
  addRole,
  deleteSkinPackWithUid,
  deleteUserDoc,
  removeRole,
} from "../firebase/firestore";
import { User, UserTypes } from "../interfaces/common";
import { User as UserFB } from "firebase/auth";
import { setUsersState } from "../store/slices/usersSlice";

export const DEFAULT_USER: User = {
  uid: "",
  displayName: "",
  photoURL: "",
  email: "",
  authorized: false,
  canEdit: false,
  admin: false,
  skinPacks: [],
};

export function createUserObject(user: UserFB): User {
  return {
    uid: user.uid || "",
    displayName: user.displayName || "",
    photoURL: user.photoURL || "",
    email: user.email || "",
    authorized: false,
    canEdit: false,
    admin: false,
    skinPacks: [],
  };
}

export const USER_TYPES: UserTypes = {
  ALL: "all",
  UNAUTHORIZED: "unauthorized",
  AUTHORIZED: "authorized",
  CAN_EDIT: "can_edit",
  ADMINS: "admins",
  BLACKLISTED: "blacklisted",
};

export function deleteUser(
  user: User,
  usersCollection: User[],
  dispatch: Dispatch,
  blacklist: boolean = false
) {
  user.skinPacks.forEach(
    async (pack) => await deleteSkinPackWithUid(pack, user)
  );
  removeRole(user.email, "authorized");
  removeRole(user.email, "canEdit");
  removeRole(user.email, "admins");
  deleteUserDoc(user.email);
  const users = [...usersCollection];
  const index = users.findIndex((u) => u.email === user.email);
  users.splice(index, 1);
  dispatch(setUsersState(users));
  if (blacklist) {
    addRole(user.email, "blacklist");
  }
}
