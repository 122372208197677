import { Skin } from "../../../interfaces/skinPacker";
import { Tag, List, Typography, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import useSkinPack from "../../../hooks/useSkinPack";

const { Title } = Typography;

interface Props {
  buttons?: boolean;
  onClick?: (index: number) => void;
}

export default function SkinsSummary({ buttons, onClick = () => {} }: Props) {
  const { skinPack } = useSkinPack();
  return (
    <List
      header={
        skinPack.title && (
          <Title level={3} style={{ margin: 0 }}>
            {skinPack.title}
          </Title>
        )
      }
      bordered
      dataSource={skinPack.skins}
      style={{ flex: 2, maxHeight: 600, overflowY: "scroll" }}
      renderItem={(skin: Skin, index: number) => (
        <List.Item>
          <div className="container full-width justify-btw align-center">
            <div className="container gap-8 justify-left align-center full-width">
              <img src={skin.texture} width={32} alt={skin.name} />
              {buttons ? (
                <Button
                  type="link"
                  style={{ margin: 0, padding: 0, height: "unset" }}
                  onClick={() => onClick(index)}
                  icon={<EyeOutlined />}
                >
                  {skin.name}
                </Button>
              ) : (
                <span className="full-width text-left">{skin.name}</span>
              )}
            </div>
            <div className="container full-width align-center justify-end gap-4">
              <Tag
                color={skin.skinModel === "default" ? "orange" : "magenta"}
                style={{ textTransform: "capitalize" }}
              >
                {skin.skinModel}
              </Tag>
              <Tag
                color={skin.skinType === "paid" ? "red" : "green"}
                style={{ textTransform: "capitalize" }}
              >
                {skin.skinType}
              </Tag>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
}
