import { useAppDispatch, useAppSelector } from "./store";
import {
  addTexturePackID,
  addTextureSlot,
  removeTexturePackID,
  setSlot,
  setSlotsState,
} from "../store/slices/tpCreatorSlice";
import { TPCreator } from "../interfaces/texturePackCreator";

export default function useTPCreator() {
  const TPCreator = useAppSelector((state) => state.TPCreator);
  const dispatch = useAppDispatch();

  const addTextureToSlot = (
    slot: string,
    blockID: string,
    textureURL: string
  ) => {
    dispatch(addTextureSlot({ slot, blockID, textureURL }));
  };

  const addTextureToPackID = (
    packID: string,
    blockID: string,
    textureURL: string
  ) => {
    if (!packID) packID = crypto.randomUUID();
    dispatch(addTexturePackID({ packID, blockID, textureURL }));
  };

  const deleteTextureToPackID = (packID: string, blockID: string) => {
    dispatch(removeTexturePackID({ packID, blockID }));
  };

  const createSlot = (slot: number, packID: string) => {
    dispatch(
      setSlot({ slot, slotValue: { packID, textures: {} }, action: "set" })
    );
  };

  const deleteSlot = (slot: number, packID: string) => {
    dispatch(
      setSlot({
        slot,
        slotValue: { packID: "", textures: {} },
        packID,
        action: "delete",
      })
    );
  };

  const setSlots = (slots: TPCreator) => {
    dispatch(setSlotsState(slots));
  };

  return {
    TPCreator,
    addTextureToSlot,
    addTextureToPackID,
    createSlot,
    setSlots,
    deleteTextureToPackID,
    deleteSlot,
  };
}
