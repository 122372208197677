import { Button, ButtonProps, Tooltip, Upload } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { uploadTexture } from "../../firebase/texturePackCreator";
import { RcFile } from "antd/es/upload";
import { useState } from "react";

interface Payload {
  packID: string;
  blockID: string;
  textureURL: string;
}

interface Props {
  filePath: string;
  packID: string;
  blockID: string;
  addTextureFn: (packID: string, blockID: string, textureURL: string) => void;
  buttonProps: ButtonProps;
  tooltip: string;
}

export default function TextureUpload({
  filePath,
  packID,
  blockID,
  addTextureFn,
  buttonProps,
  tooltip,
}: Props) {
  const [loading, setLoading] = useState(false);

  const handleUpload = (file: RcFile) => {
    setLoading(true);
    uploadTexture(file, filePath, packID)
      .then((textureURL) => {
        addTextureFn(packID, blockID, textureURL);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <Upload
      disabled={loading}
      accept="image/png"
      showUploadList={false}
      customRequest={({ file }) => handleUpload(file as RcFile)}
    >
      <Tooltip title={tooltip}>
        <Button
          icon={!loading ? buttonProps.icon : <LoadingOutlined />}
          size={buttonProps.size}
          type={buttonProps.type}
          disabled={loading || buttonProps.disabled}
        />
      </Tooltip>
    </Upload>
  );
}
