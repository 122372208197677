import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ResourcePack } from "../../interfaces/resourcePacker";
import { newResourcePack } from "../../utils/resourcePacker";

const initialState: ResourcePack = newResourcePack();

export const resourcePackSlice = createSlice({
  name: "resourcePack",
  initialState,
  reducers: {
    setResourcePackState: (state, action: PayloadAction<ResourcePack>) => {
      return action.payload;
    },
  },
});

export default resourcePackSlice.reducer;

export const { setResourcePackState } = resourcePackSlice.actions;
