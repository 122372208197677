import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import JSZip from "jszip";
const { Dragger } = Upload;

interface Props {
  type: ".zip" | ".mcworld";
  multiple?: boolean;
  defaultFiles: UploadFile<any>[];
  upload: (file: JSZip) => void;
  remove?: (uid: string) => void;
  title: string;
  description: string;
  showUploadList?: boolean;
}

export default function UploadResourceFile({
  type,
  multiple,
  defaultFiles,
  upload,
  remove = () => {},
  title,
  description,
  showUploadList = false,
}: Props) {
  const accept = type;
  const props: UploadProps = {
    name: "file",
    accept,
    multiple,
    showUploadList,
    listType: "picture",
    defaultFileList: [...defaultFiles],
    progress: { strokeWidth: 2, showInfo: true },
    beforeUpload: (file) => {
      // Convertir el Blob en un imageDataUrl
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target) {
          const fileContent = event.target.result;

          try {
            if (!fileContent) {
              return;
            }
            const zip = await JSZip.loadAsync(fileContent);
            upload(zip);
          } catch (error) {
            console.error("Error al cargar el archivo:", error);
          }
        }
      };

      reader.readAsArrayBuffer(file);

      return false;
    },
    onRemove: (file) => remove(file.uid),
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{description}</p>
    </Dragger>
  );
}
