import { ResourcePack } from "../interfaces/resourcePacker";

const resourcePackObject: ResourcePack = {
  title: "",
  description: "",
  uuid: "",
  uuidModules: "",
  version: [1, 0, 0],
  screenshots: [],
  keyart: {
    uid: "",
    name: "",
    url: "",
  },
  partnerArt: {
    uid: "",
    name: "",
    url: "",
  },
  panorama: {
    uid: "",
    name: "",
    url: "",
  },
  packIcon: {
    uid: "",
    name: "",
    url: "",
  },
  resource: null,
  behavior: null,
  type: "resource",
};

export default resourcePackObject;
