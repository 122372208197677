import React from "react";
import { Input, Select, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { TAGS, getColor } from "../../utils/packTags";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Filter } from "../../interfaces/common";
import STATUS from "../../utils/status";

interface Props {
  setFilter: (value: Filter) => void;
  filters: Filter;
}

export default function Filters({ setFilter, filters }: Props) {
  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={getColor(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, textTransform: "capitalize" }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="container gap-4 full-width">
      <Input
        prefix={<SearchOutlined />}
        style={{ flex: 3 }}
        placeholder="Search by title or description..."
        onChange={(e) => setFilter({ ...filters, search: e.target.value })}
        size="large"
      />
      <Select
        mode="tags"
        tagRender={tagRender}
        style={{ flex: 3 }}
        options={TAGS}
        placeholder="Filter by tags"
        onChange={(value) => setFilter({ ...filters, tags: [...value] })}
        size="large"
      />
      <Select
        style={{ flex: 1 }}
        options={[
          {
            value: "",
            label: "All",
          },
        ].concat(
          Object.keys(STATUS).map((status) => ({
            value: status,
            label: STATUS[status].label,
          }))
        )}
        placeholder="Filter by status"
        onChange={(value) => setFilter({ ...filters, status: value })}
        size="large"
      />
    </div>
  );
}
