import React, { useState } from "react";
import { Button, Steps, theme } from "antd";
import Title from "../../../components/SkinsPacker/Steps/PackInformation";
import SkinUpload from "../../../components/SkinsPacker/Steps/SkinUpload";
import SkinSettings from "../../../components/SkinsPacker/Steps/SkinsSettings";
import PackagerStep from "../../../components/PackagerStep";
import Package from "../../../components/SkinsPacker/Steps/Package";
import { checkSkinPack, newSkinPack } from "../../../utils/skinPacker";
import { useDispatch } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PartnerArt from "../../../components/Common/PartnerArt";
import useSkinPack from "../../../hooks/useSkinPack";

export default function Packer() {
  const { skinPack: pack, setSkinPack } = useSkinPack();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = [
    {
      title: "Pack information",
      content: <Title />,
    },
    {
      title: "Partner art",
      content: <PartnerArt packType="skinPack" />,
    },
    {
      title: "Upload skins",
      content: <SkinUpload />,
    },
    {
      title: "Setup skins",
      content: <SkinSettings />,
    },
    {
      title: "Check",
      content: <Package />,
    },
  ];

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 24,
    maxHeight: 710,
  };

  const onChange = (value: number) => {
    setCurrent(value);
  };

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Skin Packer</title>
      </Helmet>
      <Steps current={current} items={items} onChange={onChange} />
      <div style={contentStyle}>
        <PackagerStep title={steps[current].title}>
          {steps[current].content}
        </PackagerStep>
      </div>
      <div
        style={{ marginTop: 12 }}
        className="container full-width justify-end"
      >
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            size="large"
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current === 0 && (
          <Button
            type="dashed"
            size="large"
            style={{ margin: "0 8px" }}
            danger
            onClick={() => setSkinPack(newSkinPack())}
          >
            New pack
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" size="large" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            size="large"
            type="primary"
            onClick={() =>
              checkSkinPack(pack, dispatch)
                ? navigate("/packer/skins/success")
                : navigate("/packer/skins/error")
            }
            icon={<CheckOutlined />}
          >
            Finish
          </Button>
        )}
      </div>
    </>
  );
}
