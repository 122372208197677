import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { PackError } from "../../interfaces/packCommon";

const initialState: PackError[] = [];

export const packErrorsSlice = createSlice({
  name: "packErrors",
  initialState,
  reducers: {
    setPackErrorsState: (state, action: PayloadAction<PackError[]>) => {
      return action.payload;
    },
  },
});

export default packErrorsSlice.reducer;

export const { setPackErrorsState } = packErrorsSlice.actions;
