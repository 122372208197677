import { WorldPack } from "../interfaces/worldPacker";

const worldPackObject: WorldPack = {
  title: "",
  description: "",
  author: "",
  uuid: "",
  uuidModules: "",
  version: [1, 0, 0],
  screenshots: [],
  keyart: {
    uid: "",
    name: "",
    url: "",
  },
  partnerArt: {
    uid: "",
    name: "",
    url: "",
  },
  panorama: {
    uid: "",
    name: "",
    url: "",
  },
  packIcon: {
    uid: "",
    name: "",
    url: "",
  },
  world: null,
  behaviorPack: false,
  resourcePack: false,
};

export default worldPackObject;
