import { useAppDispatch, useAppSelector } from "./store";
import { setRotationPlayState } from "../store/slices/rotationPlayReducer";

export default function useRotationPlay() {
  const rotationPlay = useAppSelector((state) => state.rotationPlay);
  const dispatch = useAppDispatch();

  const setRotationPlay = (state: boolean) => {
    dispatch(setRotationPlayState(state));
  };

  return {
    rotationPlay,
    setRotationPlay,
  };
}
