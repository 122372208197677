import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker, Input, Select, Button, message, Tag } from "antd";
import { addCalendarEvent } from "../../firebase/firestore";
import { CalendarOutlined } from "@ant-design/icons";
import { COLORS } from "../../utils/common";
import useUser from "../../hooks/useUser";

interface Props {
  currentDate: string;
  fetchYearCalendar: () => void;
}

export default function AddEvent({ currentDate, fetchYearCalendar }: Props) {
  const { currentUser } = useUser();
  const [newEvent, setNewEvent] = useState({
    date: currentDate,
    label: "",
    color: "blue",
  });

  useEffect(() => {
    setNewEvent((prev) => ({ ...prev, date: currentDate }));
  }, [currentDate]);

  function handleAddEvent() {
    const { date, label, color } = newEvent;
    if (date && label && color) {
      addCalendarEvent(date, { label, color }, currentUser.uid).then(
        (result) => {
          if (result) {
            message.success("Event added to the calendar.", 5);
            fetchYearCalendar();
            setNewEvent({ date: currentDate, label: "", color: "blue" });
          } else {
            message.error("An error occured.", 5);
          }
        }
      );
    } else {
      message.error("All fields must be completed.", 5);
    }
  }

  return (
    <div className="container align-center justify-start gap-8 full-width">
      <DatePicker
        value={dayjs(newEvent.date)}
        placeholder="Select date"
        onChange={(value) =>
          setNewEvent((prev) => ({
            ...prev,
            date: dayjs(value).format("YYYY-M-D"),
          }))
        }
        style={{ flex: 1 }}
      />
      <Input
        placeholder="Event title"
        maxLength={30}
        showCount
        value={newEvent.label}
        onChange={(e) =>
          setNewEvent((prev) => ({
            ...prev,
            label: e.target.value,
          }))
        }
        style={{ flex: 2 }}
      />
      <Select
        options={COLORS.filter((color) => color.value !== "red")}
        className="full-width"
        placeholder="Color"
        value={newEvent.color}
        onChange={(value) =>
          setNewEvent((prev) => ({
            ...prev,
            color: value,
          }))
        }
        style={{ flex: 1 }}
      />
      <Tag
        style={{ flex: 1, margin: 0 }}
        color={newEvent.color}
        bordered={false}
      >
        {newEvent.label || "New Event"}
      </Tag>
      <Button
        type="primary"
        onClick={handleAddEvent}
        icon={<CalendarOutlined />}
        style={{ flex: 1 }}
      >
        Add event
      </Button>
    </div>
  );
}
