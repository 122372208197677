import React, { useEffect, useMemo, useState } from "react";
import { App, Card, Image, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SkinPackFB } from "../../../interfaces/skinPacker";
import SkinPreview from "../../../components/SkinsPacker/SkinPreview/SkinPreview";
import { getSkinPackCollections } from "../../../firebase/firestore";
import { Helmet } from "react-helmet";
import Metadata from "../../../components/Packs/Metadata";
import Paginate from "../../../components/Common/Paginate";
import { defaultThumbnail } from "../../../settings/settings";
import useSkinPackCollection from "../../../hooks/useSkinPackCollection";
import useUser from "../../../hooks/useUser";

export default function SkinPack() {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { skinPacks } = useSkinPackCollection();
  const { currentUser: user } = useUser();
  const uid = searchParams.get("uid");

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const pack: SkinPackFB | undefined = useMemo(
    () => skinPacks.find((pack) => pack.pack.uuid === uid),
    [uid, skinPacks]
  );

  useEffect(() => {
    if (skinPacks.length === 0) {
      setLoading(true);
      getSkinPackCollections(dispatch, user.skinPacks).then((result) => {
        if (!result) {
          message.error("Something went wrong, try again");
          return;
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!pack && !loading) {
      message.error("No package found with that UUID");
      navigate("/packs");
    }
  }, [pack, loading]);

  return (
    <>
      {pack ? (
        <>
          <Helmet>
            <title>{pack.pack.title}</title>
            <meta name="description" content={pack.description} />
          </Helmet>
          <Card>
            <div className="container column gap-16">
              <div className="container align-start justify-start full-width gap-16">
                <Image
                  src={pack.pack.keyart.url || defaultThumbnail}
                  width={windowSize.innerWidth * 0.35}
                  style={{ borderRadius: 8, flex: 2 }}
                />
                <Metadata pack={pack} />
              </div>
              <div
                className="container full-width gap-16 wrap"
                style={{ overflowY: "hidden" }}
              >
                <Paginate
                  pageSizeOptions={[3, 5, 10, 20, 50]}
                  defaultPageSize={5}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} skins of ${total}`
                  }
                >
                  {pack.pack.skins.map((skin) => (
                    <SkinPreview
                      skin={skin.texture}
                      width={windowSize.innerWidth / 6}
                      badge={skin.name}
                      badgeColor="blue"
                      key={skin.uid}
                    />
                  ))}
                </Paginate>
              </div>
            </div>
          </Card>
        </>
      ) : (
        <div className="container full-width justify-center align-center full-height">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
