import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces/common";

const initialState: User[] = [];

export const userSlice = createSlice({
  name: "usersCollection",
  initialState,
  reducers: {
    setUsersState: (state, action: PayloadAction<User[]>) => {
      return action.payload;
    },
  },
});

export default userSlice.reducer;

export const { setUsersState } = userSlice.actions;
