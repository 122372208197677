import React, { useState } from "react";
import { Button, Input, Tooltip, Typography, message } from "antd";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import { formatBezierCurveInputs } from "../../utils/bezierCurve";

type InputChange =
  | {
      key: "origin";
      value: string;
    }
  | { key: "destination"; value: string }
  | { key: "checkpoint"; value: string }
  | { key: "facing"; value: string }
  | { key: "command"; value: string }
  | { key: "startingFrame"; value: string }
  | { key: "points"; value: string };

export default function Advanced() {
  const [inputs, setInputs] = useState({
    origin: "",
    destination: "",
    checkpoint: "",
    facing: "",
    points: 200,
    command: "",
    startingFrame: "0",
  });
  const [output, setOutput] = useState("");

  const handleInputChange = (input: InputChange) => {
    const { key, value } = input;
    setInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleGenerate = () => {
    const { origin, destination, checkpoint, command, facing } = inputs;
    if (!origin || !destination || !checkpoint || !command || !facing) {
      message.error("Complete all required fields");
      return;
    }
    setOutput(formatBezierCurveInputs(inputs));
  };

  return (
    <>
      <div className="container column justify-start align-start full-width gap-8">
        <div
          className="container justify-start align-start full-width gap-8"
          style={{ flex: 1 }}
        >
          <Input
            addonBefore="Origin *"
            placeholder="x y z"
            size="large"
            value={inputs.origin}
            onChange={(e) =>
              handleInputChange({ key: "origin", value: e.target.value })
            }
            suffix={
              <Tooltip title="The origin coordinates from where the player will start the cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Destination *"
            placeholder="x y z"
            size="large"
            value={inputs.destination}
            onChange={(e) =>
              handleInputChange({
                key: "destination",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The destination coordinates where the player will end the cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Control point *"
            placeholder="x y z"
            size="large"
            value={inputs.checkpoint}
            onChange={(e) =>
              handleInputChange({
                key: "checkpoint",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip
                title={
                  <span>
                    The coordinates to where the curve will be made. See how a{" "}
                    <Link
                      href="https://en.wikipedia.org/wiki/Bézier_curve"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bézier curve
                    </Link>{" "}
                    works to understand. 'x y z' without any commas.
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Facing *"
            placeholder="x y z"
            size="large"
            value={inputs.facing}
            onChange={(e) =>
              handleInputChange({ key: "facing", value: e.target.value })
            }
            suffix={
              <Tooltip title="The coordinates where the player will face during the entire cinematic. 'x y z' without any commas.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
        </div>
        <div className="container justify-start align-start full-width gap-8">
          <Input
            addonBefore="Number of points"
            placeholder="x"
            size="large"
            value={inputs.points}
            onChange={(e) =>
              handleInputChange({
                key: "points",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The amount of teleport points to the player, the higher the number, the more fluid the cinematic is. If you are using repeting commands, 1 second of cinematic equals to 20 teleport points. Default is 200.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Input
            addonBefore="Starting frame"
            placeholder="x"
            size="large"
            value={inputs.startingFrame}
            onChange={(e) =>
              handleInputChange({
                key: "startingFrame",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The frame in which the cinematic will start. Default is 0.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
        </div>
        <div className="container justify-start align-start full-width gap-8">
          <Input
            addonBefore="Command output *"
            placeholder="execute if score @s cinematic_frame matches %index run tp @s %coords facing %facing_coords"
            size="large"
            value={inputs.command}
            onChange={(e) =>
              handleInputChange({
                key: "command",
                value: e.target.value,
              })
            }
            suffix={
              <Tooltip title="The command output. To get the index (frame number), use '%index'; to get the coordinates, use '%coords'; and to get the facing coordinates, use '%facing_coords'.">
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
          <Button size="large" type="primary" onClick={handleGenerate}>
            Generate shot
          </Button>
        </div>
      </div>
      {output && (
        <Typography>
          <Typography.Paragraph style={{ position: "relative" }}>
            <pre className="scroll">{output}</pre>
            <Tooltip title="Copy to clipboard">
              <Button
                type="primary"
                size="large"
                shape="circle"
                style={{ position: "absolute", top: 16, right: 24 }}
                icon={<CopyOutlined />}
                onClick={() => navigator.clipboard.writeText(output)}
              />
            </Tooltip>
          </Typography.Paragraph>
        </Typography>
      )}
    </>
  );
}
