import React, { useEffect } from "react";
import { Typography, Button, Tooltip } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { signInWithGoogle } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";

interface Props {
  loading: boolean;
}

export default function Login({ loading }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className="container full-width justify-center align-center full-height column gap-16"
      style={{ height: "100vh" }}
    >
      <div className="container column align-center justify-center gap-2">
        <Typography.Title style={{ margin: 0 }}>Welcome!</Typography.Title>
        <Typography.Text style={{ margin: 0 }} type="secondary">
          Please log in with your Google account to continue.
        </Typography.Text>
      </div>
      {/* <Tooltip title="Log in with your company account"> */}
      <Button
        type="primary"
        icon={<GoogleOutlined />}
        size="large"
        onClick={() => signInWithGoogle()}
        loading={loading}
      >
        Log in with Google
      </Button>
      {/* </Tooltip> */}
    </div>
  );
}
