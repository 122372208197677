import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Radio, Typography, message } from "antd";
import UploadWorldFile from "../../Common/UploadWorldFile";
import { updateResourcePack } from "../../../utils/resourcePacker";
import useResourcePack from "../../../hooks/useResourcePack";
import UploadResourceFile from "./UploadResourceFile";
import JSZip from "jszip";

export default function UploadResource() {
  const dispatch = useDispatch();
  const { resourcePack: pack } = useResourcePack();
  const [resourceFile, setResourceFile] = useState<JSZip | null>(null);
  const [behaviorFile, setBehaviorFile] = useState<JSZip | null>(null);

  useEffect(() => {
    updateResourcePack({
      pack,
      key: "resource",
      value: resourceFile,
      dispatch,
    });
  }, [resourceFile]);

  useEffect(() => {
    updateResourcePack({
      pack,
      key: "behavior",
      value: behaviorFile,
      dispatch,
    });
  }, [behaviorFile]);

  return (
    <div className="container justify-start align-start full-width gap-8 scroll column">
      <Alert
        message={
          "If your behavior or resource files are not showing up in your packaged file, wait a few seconds after you upload the files to give the upload time to finish."
        }
        type="info"
        showIcon
        className="full-width"
      />
      <Typography.Text strong type="secondary">
        Pack Type
      </Typography.Text>
      <Radio.Group
        options={[
          { label: "Resource Pack", value: "resource" },
          { label: "Behavior Pack", value: "behavior" },
        ]}
        onChange={(e) =>
          updateResourcePack({
            pack,
            key: "type",
            value: e.target.value,
            dispatch,
          })
        }
        value={pack.type}
        optionType="button"
      />
      <div className="full-width container column gap-4">
        <Typography.Paragraph style={{ marginBottom: 6 }}>
          Compress your
          <Typography.Text code>resource_pack</Typography.Text> or{" "}
          <Typography.Text code>behavior_pack</Typography.Text> folder contents
          into a<Typography.Text code>.zip</Typography.Text> file.
        </Typography.Paragraph>
        <div className="container column gap-4">
          <Typography.Text strong type="secondary">
            Resource Pack
          </Typography.Text>
          <UploadResourceFile
            type=".zip"
            defaultFiles={[]}
            upload={(file) => {
              setResourceFile(file);
            }}
            title="Click or drag file to this area to upload"
            description="Upload the resource pack folder here."
            showUploadList
            remove={() => setResourceFile(null)}
          />
        </div>
        {pack.type === "behavior" && (
          <div className="container column gap-4">
            <Typography.Text strong type="secondary">
              Behavior Pack
            </Typography.Text>
            <UploadResourceFile
              type=".zip"
              defaultFiles={[]}
              upload={(file) => {
                setBehaviorFile(file);
              }}
              title="Click or drag file to this area to upload"
              description="Upload the behavior pack folder here."
              showUploadList
              remove={() => setBehaviorFile(null)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
