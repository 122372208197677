import React, { useRef, useEffect } from "react";
import { SkinViewer, IdleAnimation } from "skinview3d";
import { Button, Tooltip, Badge } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { setRotationSetting } from "../../../utils/localStorage";
import useRotationPlay from "../../../hooks/useRotationPlay";

interface Props {
  skin: string;
  badge: string;
  badgeColor: string;
  width?: number;
  hideButton?: boolean;
  stopRotation?: boolean;
}

function SkinPreview({
  skin,
  badge,
  badgeColor,
  width = 375,
  hideButton,
  stopRotation,
}: Props) {
  const { rotationPlay, setRotationPlay } = useRotationPlay();
  const skinViewerRef = useRef(null);

  useEffect(() => {
    // Aquí puedes configurar y usar el SkinViewer
    if (skinViewerRef.current) {
      const skinViewer = new SkinViewer({
        canvas: skinViewerRef.current,
        width: width,
        height: width * 1.6,
      });
      // Carga la piel de Minecraft
      if (skin) {
        skinViewer.loadSkin(skin);
      }
      skinViewer.autoRotate = !stopRotation && rotationPlay;

      skinViewer.autoRotateSpeed = 0.3;
      skinViewer.animation = new IdleAnimation();

      // Set the speed of the animation
      skinViewer.animation.speed = 1;
    }
  }, [rotationPlay, skin, width]);

  function handleRotationSetting() {
    setRotationSetting(!rotationPlay);
    setRotationPlay(!rotationPlay);
  }

  return (
    <>
      <Badge.Ribbon text={badge} color={badgeColor}>
        <div
          className="skin-preview"
          style={{
            height: width * 1.6,
            width: width,
          }}
        >
          <canvas ref={skinViewerRef} />
          <Tooltip title={!rotationPlay ? "Start rotation" : "Pause rotation"}>
            {!hideButton && (
              <Button
                type="primary"
                shape="circle"
                className="float-button"
                size="large"
                icon={
                  !rotationPlay ? (
                    <PlayCircleOutlined />
                  ) : (
                    <PauseCircleOutlined />
                  )
                }
                onClick={() => handleRotationSetting()}
              />
            )}
          </Tooltip>
        </div>
      </Badge.Ribbon>
    </>
  );
}

export default SkinPreview;
