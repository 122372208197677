import React, { ReactNode } from "react";
import { Typography } from "antd";
const { Title } = Typography;

interface Props {
  children: ReactNode;
  title: string;
}

export default function PackagerStep({ children, title }: Props) {
  return (
    <div className="full-width text-left full-height">
      <Title level={2} underline className="no-margin">
        {title}
      </Title>
      {children}
    </div>
  );
}
