export function setRotationSetting(value: boolean): void {
  localStorage.setItem("rotationPlay", String(value));
}

export function getRotationSetting(): boolean {
  const rotationPlay = localStorage.getItem("rotationPlay");
  if (rotationPlay) {
    return JSON.parse(rotationPlay);
  }
  return false;
}

export function setLanguageLS(value: string): void {
  localStorage.setItem("language", value);
}

export function getLanguageLS(): string {
  const language = localStorage.getItem("language");
  if (language) {
    return language;
  }
  return "english";
}
