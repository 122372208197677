import React, { useState } from "react";
import { SkinPackFB } from "../../interfaces/skinPacker";
import { Space, Tag, Typography, message } from "antd";
import { useDispatch } from "react-redux";
import { getColor } from "../../utils/packTags";
import STATUS from "../../utils/status";
import ActionButtons from "./SkinPack/ActionButtons";
import dayjs from "dayjs";
import { PackHeader } from "../../interfaces/packCommon";
import {
  editPackHeader,
  getSkinPackCollections,
} from "../../firebase/firestore";
import useUser from "../../hooks/useUser";

interface Props {
  pack: SkinPackFB;
}

export default function Metadata({ pack }: Props) {
  const { currentUser } = useUser();
  const dispatch = useDispatch();
  const [header, setHeader] = useState<PackHeader>({
    title: pack.pack.title,
    description: pack.description,
  });

  async function handleHeaderChange(packHeader: PackHeader) {
    await editPackHeader(pack.pack.uuid, packHeader).then((result) => {
      if (result) {
        message.success("Pack description changed.", 5);
        getSkinPackCollections(dispatch, currentUser.skinPacks).then(
          (result) => {
            !result && message.error("Something went wrong, try again");
          }
        );
      } else {
        message.error("An error occured.", 5);
      }
    });
  }

  return (
    <div className="container column full-width gap-8" style={{ flex: 1 }}>
      <div className="container full-width justify-btw align-center">
        {currentUser.canEdit ? (
          <Typography.Title
            style={{ margin: 0, flex: 3 }}
            editable={{
              maxLength: 40,
              onChange: (value) => {
                setHeader((prev) => ({ ...prev, title: value }));
                handleHeaderChange({
                  title: value,
                  description: header.description,
                });
              },
            }}
          >
            {header.title}
          </Typography.Title>
        ) : (
          <Typography.Title style={{ margin: 0, flex: 3 }}>
            {header.title}
          </Typography.Title>
        )}
        {currentUser.canEdit ? (
          <ActionButtons pack={pack} />
        ) : (
          <div className="container gap-4 align-center justify-end">
            {pack.status === "scheduled" && (
              <Typography.Text type="secondary">
                {dayjs(pack.schedule).format("dddd, MMMM D, YYYY")}
              </Typography.Text>
            )}
            <Tag color={STATUS[pack.status].color} bordered={false}>
              {STATUS[pack.status].label}
            </Tag>
          </div>
        )}
      </div>
      <Space size={6}>
        {pack.tags.map((tag, index) => (
          <Tag
            style={{ margin: 0, textTransform: "capitalize" }}
            color={getColor(tag)}
            key={index}
          >
            {tag}
          </Tag>
        ))}
      </Space>
      {currentUser.canEdit ? (
        <Typography.Text
          style={{ fontSize: 16 }}
          editable={{
            maxLength: 375,
            onChange: (value) => {
              setHeader((prev) => ({ ...prev, description: value }));
              handleHeaderChange({ title: header.title, description: value });
            },
          }}
        >
          {header.description || "No description"}
        </Typography.Text>
      ) : (
        <Typography.Text style={{ fontSize: 16 }}>
          {header.description || "No description"}
        </Typography.Text>
      )}
      <Typography.Text copyable type="secondary" code style={{ fontSize: 15 }}>
        {window.location.href}
      </Typography.Text>
    </div>
  );
}
