import { BezierInputs } from "../interfaces/common";

export function bezierCurve(
  origin: number[],
  destination: number[],
  checkpoint: number[],
  facing: number[],
  points: number,
  command: string,
  startingFrame: number
): string {
  let comandos: string[] = [];

  for (let i: number = 0; i < points; i++) {
    // Calcular los factores de interpolación para la curva de Bezier
    let t: number = (i + 1) / (points + 1);
    let t2: number = t * t;
    let t3: number = t2 * t;
    let one_minus_t: number = 1 - t;
    let one_minus_t2: number = one_minus_t * one_minus_t;
    let one_minus_t3: number = one_minus_t2 * one_minus_t;

    // Calcular las coordenadas interpoladas mediante la curva de Bezier
    let x_interpolado: number =
      Math.round(
        (one_minus_t3 * origin[0] +
          3 * one_minus_t2 * t * checkpoint[0] +
          3 * one_minus_t * t2 * destination[0] +
          t3 * destination[0]) *
          100000
      ) / 100000;
    let y_interpolado: number =
      Math.round(
        (one_minus_t3 * origin[1] +
          3 * one_minus_t2 * t * checkpoint[1] +
          3 * one_minus_t * t2 * destination[1] +
          t3 * destination[1]) *
          100000
      ) / 100000;
    let z_interpolado: number =
      Math.round(
        (one_minus_t3 * origin[2] +
          3 * one_minus_t2 * t * checkpoint[2] +
          3 * one_minus_t * t2 * destination[2] +
          t3 * destination[2]) *
          100000
      ) / 100000;

    // Generar el comando con las coordenadas interpoladas
    let commandString = command;
    commandString = commandString.replace("%index", String(i + startingFrame));
    commandString = commandString.replace(
      "%coords",
      `${x_interpolado} ${y_interpolado} ${z_interpolado}`
    );
    commandString = commandString.replace(
      "%facing_coords",
      `${facing[0]} ${facing[1]} ${facing[2]}`
    );

    comandos.push(commandString);
  }

  return comandos.join("\n");
}

export function formatBezierCurveInputs(inputs: BezierInputs) {
  const {
    origin,
    destination,
    checkpoint,
    facing,
    points,
    command,
    startingFrame,
  } = inputs;
  const originAux = origin.split(" ").map((x) => Number(x));
  const destinationAux = destination.split(" ").map((x) => Number(x));
  const checkpointAux = checkpoint.split(" ").map((x) => Number(x));
  const facingAux = facing.split(" ").map((x) => Number(x));
  const pointsAux = Number(points) || 200;
  const startingFrameAux = Number(startingFrame) || 0;

  return bezierCurve(
    originAux,
    destinationAux,
    checkpointAux,
    facingAux,
    pointsAux,
    command,
    startingFrameAux
  );
}
