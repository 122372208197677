import { Card } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Normal from "../../../components/CinematicGenerator/Normal";
import CardHeader from "../../../components/CinematicGenerator/CardHeader";
import Advanced from "../../../components/CinematicGenerator/Advanced";

export default function CinematicGenerator() {
  const [tab, setTab] = useState("normal");
  return (
    <>
      <Helmet>
        <title>Minecraft Content | Cinematic Generator</title>
      </Helmet>
      <Card title={<CardHeader setTab={setTab} />}>
        {tab === "normal" ? <Normal /> : <Advanced />}
      </Card>
    </>
  );
}
