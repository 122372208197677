import { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Card,
  Divider,
  Input,
  Segmented,
  Table,
  Typography,
  Checkbox,
  Button,
  Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { Roles, User } from "../../interfaces/common";
import {
  addRole,
  editTPCreatorSlots,
  getAdminUsers,
  getAuthorizedUsers,
  getBlacklisted,
  getCanEditUsers,
  getUsersCollection,
  removeRole,
} from "../../firebase/firestore";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { USER_TYPES, deleteUser } from "../../utils/user";
import { filterUsers } from "../../utils/filters";
import { DeleteOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import useUserCollection from "../../hooks/useUserCollection";

interface DataType {
  email: string;
  key: number;
}

export default function Users() {
  const dispatch = useDispatch();
  const { users: usersCollection } = useUserCollection();

  const [tab, setTab] = useState(USER_TYPES.ALL);
  const [roles, setRoles] = useState<Roles>({
    authorized: [],
    admins: [],
    canEdit: [],
    blacklisted: [],
  });
  const [query, setQuery] = useState("");

  const filteredUsers = useMemo(
    () => filterUsers(usersCollection, query, tab, roles),
    [tab, usersCollection, query]
  );

  useEffect(() => {
    getUsersCollection(dispatch);
    getBlacklisted().then((result) => {
      setRoles((prev) => ({ ...prev, blacklisted: result }));
    });
    getAuthorizedUsers().then((result) => {
      setRoles((prev) => ({ ...prev, authorized: result }));
    });
    getAdminUsers().then((result) => {
      setRoles((prev) => ({ ...prev, admins: result }));
    });
    getCanEditUsers().then((result) => {
      setRoles((prev) => ({ ...prev, canEdit: result }));
    });
  }, []);

  function toggleRole(
    email: string,
    role: keyof Roles,
    forceDelete: boolean = false
  ) {
    const roleSet = new Set(roles[role]);
    if (role === USER_TYPES.AUTHORIZED && roleSet.has(email)) {
      toggleRole(email, "canEdit", true);
      toggleRole(email, "admins", true);
    }
    if (!roleSet.delete(email) && !forceDelete) {
      roleSet.add(email);
      addRole(email, role);
    } else {
      removeRole(email, role);
    }
    setRoles((prev) => ({ ...prev, [role]: Array.from(roleSet) }));
  }

  const columns_bl: ColumnsType<DataType> = [
    { title: "E-Mail", dataIndex: "email" },
  ];

  const columns: ColumnsType<User> = [
    {
      title: "",
      dataIndex: "avatar",
      render: (_, record) => <Avatar src={record.photoURL} size="large" />,
      width: 40,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        <Typography.Text style={{ margin: 0, lineHeight: 2 }}>
          {record.displayName}
        </Typography.Text>
      ),
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      render: (_, record) => (
        <Typography.Text type="secondary" ellipsis style={{ margin: 0 }}>
          {record.email}
        </Typography.Text>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="container justify-start align-center gap-2">
          <Checkbox
            checked={roles.authorized.includes(record.email)}
            onChange={(e) => toggleRole(record.email, e.target.value)}
            value="authorized"
          >
            Authorized
          </Checkbox>
          <Checkbox
            value="canEdit"
            checked={roles.canEdit.includes(record.email)}
            onChange={(e) => toggleRole(record.email, e.target.value)}
            disabled={!roles.authorized.includes(record.email)}
          >
            Can Edit
          </Checkbox>
          <Checkbox
            value="admins"
            checked={roles.admins.includes(record.email)}
            onChange={(e) => toggleRole(record.email, e.target.value)}
            disabled={!roles.authorized.includes(record.email)}
          >
            Admin
          </Checkbox>
          <div className="container justify-start align-center gap-8">
            <Popconfirm
              title="Delete user"
              description="Are you sure you want to delete this user?"
              okText="Yes, delete"
              okType="danger"
              icon={<DeleteOutlined style={{ color: "#cc0000" }} />}
              onConfirm={() => {
                deleteUser(record, usersCollection, dispatch);
              }}
            >
              <Button danger type="primary" icon={<DeleteOutlined />}>
                Delete user
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Balcklist and delete user"
              description="Are you sure you want to blacklist this user? This will also delete the user."
              okText="Yes, delete"
              okType="danger"
              icon={<DeleteOutlined style={{ color: "#cc0000" }} />}
              onConfirm={() => {
                deleteUser(record, usersCollection, dispatch, true);
                toggleRole(record.email, "blacklisted");
              }}
            >
              <Button danger>Blacklist</Button>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Minecraft Content | Users</title>
      </Helmet>
      <Card
        title={
          <>
            <div className="container full-width justify-start align-center">
              <Segmented
                size="large"
                onChange={(value) => setTab(value.toString())}
                options={[
                  {
                    label: "All",
                    value: USER_TYPES.ALL,
                  },
                  {
                    label: "Unauthorized",
                    value: USER_TYPES.UNAUTHORIZED,
                  },
                  {
                    label: "Authorized",
                    value: USER_TYPES.AUTHORIZED,
                  },
                  {
                    label: "Can Edit",
                    value: USER_TYPES.CAN_EDIT,
                  },
                  {
                    label: "Admins",
                    value: USER_TYPES.ADMINS,
                  },
                  {
                    label: "Blacklisted",
                    value: USER_TYPES.BLACKLISTED,
                  },
                ]}
              />
              <Divider type="vertical" style={{ height: 32 }} />
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search by name or e-mail..."
                onChange={(e) => setQuery(e.target.value)}
                size="large"
              />
            </div>
          </>
        }
        style={{ width: "100%" }}
      >
        {tab === USER_TYPES.BLACKLISTED ? (
          <Table
            columns={columns_bl}
            dataSource={roles?.blacklisted.map((email, index) => ({
              email,
              key: index,
            }))}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredUsers.map((user) => ({
              ...user,
              key: user.uid,
            }))}
            pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
          />
        )}
      </Card>
    </>
  );
}
