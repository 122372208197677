import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";

const { Dragger } = Upload;

interface Props {
  type: "jpeg" | "png";
  multiple?: boolean;
  defaultFiles: UploadFile<any>[];
  upload: (url: string, uid: string) => void;
  remove?: (uid: string) => void;
  title: string;
  description: string;
  showUploadList?: boolean;
}

export default function UploadImage({
  type,
  multiple,
  defaultFiles,
  upload,
  remove = () => {},
  title,
  description,
  showUploadList = false,
}: Props) {
  const accept = "image/" + type;
  const props: UploadProps = {
    name: "file",
    accept,
    multiple,
    showUploadList,
    listType: "picture",
    defaultFileList: [...defaultFiles],
    progress: { strokeWidth: 2, showInfo: true },
    beforeUpload: (file) => {
      const validFile = file.type === accept;
      if (!validFile) {
        message.error(`${file.name} is not a ${type} file`, 5);
        return validFile || Upload.LIST_IGNORE;
      }

      // Convertir el Blob en un imageDataUrl
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const imageDataUrl = event.target.result as string;
          upload(imageDataUrl, file.uid);
        }
      };
      reader.readAsDataURL(file);

      return false;
    },
    onRemove: (file) => remove(file.uid),
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{description}</p>
    </Dragger>
  );
}
