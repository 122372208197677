import { v4 as uuidv4, validate as validateUuidV4 } from "uuid";
import { message } from "antd";
import { Color } from "../interfaces/common";

export const PACK_TYPES = {
  skin_pack: "skin_pack",
  world_template: "world_template",
};

export const COLORS: Color[] = [
  { value: "pink", label: "Pink" },
  { value: "red", label: "Red" },
  { value: "yellow", label: "Yellow" },
  { value: "orange", label: "Orange" },
  { value: "cyan", label: "Cyan" },
  { value: "green", label: "Green" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "geekblue", label: "Geekblue" },
  { value: "magenta", label: "Magenta" },
  { value: "volcano", label: "Volcano" },
  { value: "gold", label: "Gold" },
  { value: "lime", label: "Lime" },
];

export function removeSpaces(value: string): string {
  return value.split(" ").join("");
}

export function generateOrValidateUuidV4(value: string) {
  if (validateUuidV4(value)) {
    return value;
  } else {
    message.error(
      "A new UUID was generated because the one provided was invalid.",
      5
    );
    return uuidv4();
  }
}

export async function decodeImage(imageData: string): Promise<Blob> {
  if (imageData.startsWith("data:image")) {
    // Si es una cadena Base64
    return base64ToBlob(imageData);
  } else {
    // Si es una URL de imagen, primero la descargamos y luego la convertimos en blob
    return fetch(imageData).then((response) => response.blob());
  }
}

export function base64ToBlob(image: string): Blob {
  let binary = atob(image.split(",")[1]);
  let array = [];
  for (let j = 0; j < binary.length; j++) {
    array.push(binary.charCodeAt(j));
  }
  let imageBlob = new Blob([new Uint8Array(array)], {
    type: "image/png",
  });
  return imageBlob;
}

export function formatString(string: string) {
  string = string.replace(/\s+/g, " ");
  string.trim();
  const capitalize = string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalize.join(" ").trim();
}

export function resizeImage(
  imageBlob: Blob,
  newWidth: number,
  newHeight: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target!.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx!.drawImage(img, 0, 0, newWidth, newHeight);
        const resizedImageUrl = canvas.toDataURL("image/jpeg", 1);
        resolve(decodeImage(resizedImageUrl));
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(imageBlob);
  });
}

export function convertJpgToPng(jpgUrl: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.src = jpgUrl;

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      // Convert the image to PNG format
      var pngDataUrl = canvas.toDataURL("image/png");
      resolve(decodeImage(pngDataUrl));
    };
  });
}
