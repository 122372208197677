import { useState, useEffect } from "react";
import {
  Input,
  Image,
  Typography,
  InputNumber,
  Space,
  Button,
  Tooltip,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { KeyartFile } from "../../../interfaces/packCommon";
import { validate, v4 as uuidv4 } from "uuid";
import { formatString } from "../../../utils/common";
import { updateWorldPack } from "../../../utils/worldPacker";
import UploadImage from "../../Common/UploadImage";
import { defaultThumbnail } from "../../../settings/settings";
import useWorldPack from "../../../hooks/useWorldPack";
const { Text } = Typography;

export default function PackInformation() {
  const { worldPack: pack } = useWorldPack();
  const [fileList, setFileList] = useState<KeyartFile[]>([pack.keyart]);
  const dispatch = useDispatch();

  useEffect(() => {
    updateWorldPack({
      dispatch,
      key: "keyart",
      value: { ...fileList[0] },
      pack,
    });
  }, [fileList]);

  return (
    <div className="container justify-start align-start full-width gap-16">
      <div
        className="container column justify-start align-start full-width gap-8"
        style={{ flex: 1 }}
      >
        <Input
          addonBefore="Title"
          placeholder="The Valley of Legends"
          size="large"
          defaultValue={pack.title}
          showCount
          maxLength={40}
          value={pack.title}
          onBlur={() =>
            updateWorldPack({
              pack,
              key: "title",
              value: formatString(pack.title),
              dispatch,
            })
          }
          onChange={(e) =>
            updateWorldPack({
              pack,
              key: "title",
              value: e.target.value,
              dispatch,
            })
          }
        />
        <Input
          addonBefore="Author"
          placeholder="FineBlock"
          size="large"
          defaultValue={pack.author}
          value={pack.author}
          onChange={(e) =>
            updateWorldPack({
              pack,
              key: "author",
              value: e.target.value,
              dispatch,
            })
          }
          onBlur={() =>
            updateWorldPack({
              pack,
              key: "author",
              value: formatString(pack.author),
              dispatch,
            })
          }
        />
        <Input
          addonBefore="Description"
          placeholder="By FineBlock"
          size="large"
          defaultValue={pack.description}
          showCount
          maxLength={40}
          value={pack.description}
          onBlur={() =>
            updateWorldPack({
              pack,
              key: "description",
              value: pack.description,
              dispatch,
            })
          }
          onChange={(e) =>
            updateWorldPack({
              pack,
              key: "description",
              value: e.target.value,
              dispatch,
            })
          }
        />
        <Space.Compact block size="large">
          <Input
            addonBefore="Pack UUID"
            placeholder="Pack UUID"
            size="large"
            defaultValue={pack.uuid}
            maxLength={36}
            status={!validate(pack.uuid) ? "error" : ""}
            value={pack.uuid}
            onChange={(e) =>
              updateWorldPack({
                pack,
                key: "uuid",
                value: e.target.value,
                dispatch,
              })
            }
          />
          <Tooltip title="Generate new UUID">
            <Button
              icon={<ReloadOutlined />}
              size="large"
              onClick={() =>
                updateWorldPack({
                  pack,
                  key: "uuid",
                  value: uuidv4(),
                  dispatch,
                })
              }
            />
          </Tooltip>
        </Space.Compact>
        <Space.Compact block size="large">
          <Input
            addonBefore="Modules UUID"
            placeholder="Modules UUID"
            size="large"
            defaultValue={pack.uuidModules}
            maxLength={36}
            status={!validate(pack.uuidModules) ? "error" : ""}
            value={pack.uuidModules}
            onChange={(e) =>
              updateWorldPack({
                pack,
                key: "uuidModules",
                value: e.target.value,
                dispatch,
              })
            }
          />
          <Tooltip title="Generate new UUID">
            <Button
              icon={<ReloadOutlined />}
              size="large"
              onClick={() =>
                updateWorldPack({
                  pack,
                  key: "uuidModules",
                  value: uuidv4(),
                  dispatch,
                })
              }
            />
          </Tooltip>
        </Space.Compact>
        <Space.Compact size="large">
          <InputNumber
            min={1}
            value={pack.version[0]}
            size="large"
            addonBefore="Version"
            onChange={(value) => {
              updateWorldPack({
                pack,
                key: "version",
                value: [value || 1, pack.version[1], pack.version[2]],
                dispatch,
              });
            }}
          />
          <InputNumber
            min={0}
            value={pack.version[1]}
            size="large"
            onChange={(value) => {
              updateWorldPack({
                pack,
                key: "version",
                value: [pack.version[0], value || 0, pack.version[2]],
                dispatch,
              });
            }}
          />
          <InputNumber
            min={0}
            value={pack.version[2]}
            size="large"
            onChange={(value) => {
              updateWorldPack({
                pack,
                key: "version",
                value: [pack.version[0], pack.version[1], value || 0],
                dispatch,
              });
            }}
          />
        </Space.Compact>
        <Text type="secondary" strong>
          Keyart
        </Text>
        <div className="full-width">
          <UploadImage
            type="jpeg"
            defaultFiles={[pack.partnerArt]}
            upload={(url, uid) =>
              setFileList([
                {
                  uid: uid,
                  url: url || "",
                  name: "",
                },
              ])
            }
            title="Click or drag file to this area to upload"
            description="Upload the keyart here. jpg (1920x1080) files only"
          />
        </div>
      </div>
      <Image
        height={450}
        src={fileList[0].url || defaultThumbnail}
        style={{ flex: 3, borderRadius: 8 }}
        placeholder={false}
      />
    </div>
  );
}
