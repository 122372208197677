import { Divider, Segmented } from "antd";

interface Props {
  setTab: (value: string) => void;
}

export default function CardHeader({ setTab }: Props) {
  const tabs = [
    {
      label: `Normal`,
      value: "normal",
    },
    {
      label: `Advanced`,
      value: "advanced",
    },
  ];

  return (
    <div className="container full-width justify-start align-center">
      <span>Cinematic Generator</span>
      <Divider type="vertical" style={{ height: 32 }} />
      <Segmented
        size="large"
        onChange={(value) => setTab(value.toString())}
        options={tabs}
      />
    </div>
  );
}
