interface Props {
  image: string;
  alt?: string;
  onClick: (image: string) => void;
}

export default function ImageButton({ image, alt = "Image", onClick }: Props) {
  return (
    <button className="image-button" onClick={() => onClick(image)}>
      <img src={image} alt={alt} />
    </button>
  );
}
