import React, { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Input, Radio, Typography } from "antd";
import SkinPreview from "./SkinPreview";
import { useDispatch } from "react-redux";
import { Skin, SkinModel, SkinType } from "../../../interfaces/skinPacker";
import { updateSkinPack, updateSkin } from "../../../utils/skinPacker";
import { CloseCircleOutlined } from "@ant-design/icons";
import { formatString } from "../../../utils/common";
import useSkinPack from "../../../hooks/useSkinPack";
const { Text } = Typography;

interface Props {
  skin: Skin;
}

export default function SkinSettings({ skin }: Props) {
  const { skinPack } = useSkinPack();
  const [name, setName] = useState<string>(skin.name);
  const [model, setModel] = useState<SkinModel>(skin.skinModel);
  const [type, setType] = useState<SkinType>(skin.skinType);
  const dispatch = useDispatch();

  const changeModel = (e: RadioChangeEvent) => {
    setModel(e.target.value);
    updateSkinPack({
      dispatch,
      pack: skinPack,
      key: "skins",
      value: updateSkin(skinPack.skins, { ...skin, skinModel: e.target.value }),
    });
  };

  const changeType = (e: RadioChangeEvent) => {
    setType(e.target.value);
    updateSkinPack({
      dispatch,
      pack: skinPack,
      key: "skins",
      value: updateSkin(skinPack.skins, { ...skin, skinType: e.target.value }),
    });
  };

  const changeName = (value: string) => {
    setName(value);
    updateSkinPack({
      dispatch,
      pack: skinPack,
      key: "skins",
      value: updateSkin(skinPack.skins, { ...skin, name: value }),
    });
  };

  return (
    <div className="container justify-center align-start gap-16 full-width">
      <div className="container justify-start align-start column gap-4 full-width ">
        <Input
          addonBefore="Name"
          value={name}
          showCount
          maxLength={20}
          className="full-width"
          onBlur={() => changeName(formatString(name))}
          onChange={(e) => changeName(e.target.value)}
          size="large"
          prefix={name.length !== 0 ? "" : <CloseCircleOutlined />}
          status={name.length !== 0 ? "" : "error"}
        />
        <Text type="secondary" strong>
          Model
        </Text>
        <Radio.Group
          value={model}
          onChange={changeModel}
          className="container justify-start align-center gap-2"
        >
          <Radio value={"default"}>Default</Radio>
          <Radio value={"slim"}>Slim</Radio>
        </Radio.Group>
        <Text type="secondary" strong>
          Type
        </Text>
        <Radio.Group
          value={type}
          onChange={changeType}
          className="container justify-start align-center gap-2"
        >
          <Radio value={"paid"}>Paid</Radio>
          <Radio value={"free"}>Free</Radio>
        </Radio.Group>
      </div>
      <SkinPreview
        skin={skin.texture}
        badge={skin.skinType.toUpperCase()}
        badgeColor={skin.skinType === "paid" ? "red" : "green"}
      />
    </div>
  );
}
