import { FloatButton, Layout } from "antd";
import Packer from "./Packer/Skins/Packer";
import WorldPacker from "./Packer/World/Packer";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PackSuccess from "./Packer/Skins/PackSuccess";
import WorldPackSuccess from "./Packer/World/WorldPackSuccess";
import E404 from "./E404";
import PackError from "./Packer/PackError";
import Packs from "./Packs/Packs";
import SkinPack from "./Packs/Skins/SkinPack";
import { Content } from "antd/es/layout/layout";
import Sidebar from "../components/Page/Sidebar";
import E403 from "./E403";
import Users from "./Users/Users";
import Schedule from "./Calendar/Schedule";
import CinematicGenerator from "./Tools/CinematicGenerator/CinematicGenerator";
import E403v3 from "./E403v3";
import useUser from "../hooks/useUser";
import { useEffect } from "react";
import TexturePackCreator from "./Tools/TexturePackCreator/TexturePackCreator";
import ResourcePacker from "./Packer/Resource/Packer";
import ResourcePackSuccess from "./Packer/Resource/ResourcePackSuccess";

export default function AppPage() {
  const { currentUser } = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") navigate("/packs");
  }, [pathname]);

  const PERMISSIONS = {
    UNAUTHORIZED: "unauthorized",
    AUTHORIZED: "authorized",
    CAN_EDIT: "can_edit",
    ADMIN: "admin",
  };

  const getRouteElement = (permission: string, element: JSX.Element) => {
    if (permission === PERMISSIONS.UNAUTHORIZED) return element;
    if (permission === PERMISSIONS.AUTHORIZED && currentUser.authorized)
      return element;
    if (permission === PERMISSIONS.CAN_EDIT && currentUser.canEdit)
      return element;
    if (permission === PERMISSIONS.ADMIN && currentUser.admin) return element;
    if (!currentUser.authorized) return <E403v3 />;
    if (currentUser.authorized) return <E403 />;
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <Content style={{ margin: "16px" }}>
          <Routes>
            <Route path="*" element={<E404 />} />
            <Route path="/" element={<Packs />} />

            <Route
              path="/packer/skins"
              element={getRouteElement(PERMISSIONS.CAN_EDIT, <Packer />)}
            />
            <Route
              path="/packer/world"
              element={getRouteElement(PERMISSIONS.CAN_EDIT, <WorldPacker />)}
            />
            <Route
              path="/packer/resource"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <ResourcePacker />
              )}
            />
            <Route
              path="/packer/skins/success"
              element={getRouteElement(PERMISSIONS.CAN_EDIT, <PackSuccess />)}
            />
            <Route
              path="/packer/skins/error"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <PackError returnPath="/packer/skins" />
              )}
            />
            <Route
              path="/packer/world/success"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <WorldPackSuccess />
              )}
            />
            <Route
              path="/packer/world/error"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <PackError returnPath="/packer/world" />
              )}
            />
            <Route
              path="/packer/resource/success"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <ResourcePackSuccess />
              )}
            />
            <Route
              path="/packer/resource/error"
              element={getRouteElement(
                PERMISSIONS.CAN_EDIT,
                <PackError returnPath="/packer/resource" />
              )}
            />
            <Route
              path="/packs"
              element={getRouteElement(PERMISSIONS.AUTHORIZED, <Packs />)}
            />
            <Route
              path="/users"
              element={getRouteElement(PERMISSIONS.ADMIN, <Users />)}
            />
            <Route
              path="/packs/skin_pack"
              element={getRouteElement(PERMISSIONS.AUTHORIZED, <SkinPack />)}
            />
            <Route
              path="/tools/cinematic-generator"
              element={getRouteElement(
                PERMISSIONS.UNAUTHORIZED,
                <CinematicGenerator />
              )}
            />
            <Route
              path="/tools/texture-pack-creator"
              element={getRouteElement(
                PERMISSIONS.AUTHORIZED,
                <TexturePackCreator />
              )}
            />
            <Route
              path="/calendar"
              element={getRouteElement(PERMISSIONS.CAN_EDIT, <Schedule />)}
            />
          </Routes>
        </Content>
      </Layout>
      <FloatButton.BackTop tooltip="Back to Top" />
    </Layout>
  );
}
