import { useEffect } from "react";
import { Button, Result, Typography, Alert, Space } from "antd";
import { useNavigate } from "react-router-dom";
import usePackErrors from "../../hooks/usePackErrors";

interface Props {
  returnPath: string;
}

export default function PackError({ returnPath }: Props) {
  const navigate = useNavigate();
  const { Paragraph, Text } = Typography;
  const { packErrors: errors } = usePackErrors();
  useEffect(() => {
    if (errors.length === 0) {
      navigate(returnPath);
    }
  }, []);

  return (
    <Result
      status="error"
      title="The pack contains errors"
      subTitle="Review the errors and try again"
      extra={[
        <Button
          type="primary"
          onClick={() => navigate(returnPath)}
          key={"return"}
        >
          Return
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 24,
            }}
          >
            The pack contains the following errors:
          </Text>
        </Paragraph>
        {errors.map((error, index) => (
          <span key={index}>
            <Alert message={error} type="error" showIcon />
          </span>
        ))}
      </Space>
    </Result>
  );
}
