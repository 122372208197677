import React, { useEffect, useState } from "react";
import { updateWorldPack } from "../../../utils/worldPacker";
import { useDispatch } from "react-redux";
import { Checkbox, Typography } from "antd";
import UploadWorldFile from "../../Common/UploadWorldFile";
import useWorldPack from "../../../hooks/useWorldPack";

export default function UploadWorld() {
  const dispatch = useDispatch();
  const { worldPack: pack } = useWorldPack();
  const [worldFile, setWorldFile] = useState<Blob | null>(null);

  useEffect(() => {
    updateWorldPack({ pack, key: "world", value: worldFile, dispatch });
  }, [worldFile]);

  return (
    <div className="container justify-start align-start full-width gap-8 scroll column">
      <div className="full-width">
        <Typography.Paragraph style={{ marginBottom: 6 }}>
          Export your world from the Minecraft menu and compress that file into
          a <Typography.Text code>.zip</Typography.Text> file. You should have a{" "}
          <Typography.Text code>.mcworld</Typography.Text> file inside a{" "}
          <Typography.Text code>.zip</Typography.Text> file. Make sure the
          folder inside <Typography.Text code>behavior_packs</Typography.Text>{" "}
          and <Typography.Text code>resource_packs</Typography.Text> are named{" "}
          <Typography.Text code>BP</Typography.Text> and
          <Typography.Text code>RP</Typography.Text>, otherwise it won't work.
        </Typography.Paragraph>
        <UploadWorldFile
          type=".zip"
          defaultFiles={[]}
          upload={(file) => {
            setWorldFile(file);
          }}
          title="Click or drag file to this area to upload"
          description="Upload the world file here."
          showUploadList
        />
      </div>
      <Typography.Text strong type="secondary">
        World specifications
      </Typography.Text>
      <Checkbox
        onChange={(e) =>
          updateWorldPack({
            pack,
            key: "behaviorPack",
            value: e.target.checked,
            dispatch,
          })
        }
        defaultChecked={pack.behaviorPack}
        value={pack.behaviorPack}
      >
        Behavior Pack
      </Checkbox>
      <Checkbox
        onChange={(e) =>
          updateWorldPack({
            pack,
            key: "resourcePack",
            value: e.target.checked,
            dispatch,
          })
        }
        defaultChecked={pack.resourcePack}
        value={pack.resourcePack}
      >
        Resource Pack
      </Checkbox>
    </div>
  );
}
