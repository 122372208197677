import React from "react";
import { SkinPackFB } from "../../../interfaces/skinPacker";
import { Empty } from "antd";
import PackCard from "./SkinPackCard";

interface Props {
  packs: SkinPackFB[];
}

export default function SkinPackList({ packs }: Props) {
  return (
    <>
      {packs.length !== 0 ? (
        <div className="container gap-16 justify-start align-start wrap">
          {packs
            .sort((a, b) => b.status.localeCompare(a.status))
            .map((pack) => (
              <PackCard pack={pack} key={pack.pack.uuid} />
            ))}
        </div>
      ) : (
        <div className="container full-width justify-center align-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </>
  );
}
