import React, { useEffect, useState } from "react";
import { Button, Result, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { createWorldPackZip, newWorldPack } from "../../../utils/worldPacker";
import useWorldPack from "../../../hooks/useWorldPack";

export default function WorldPackSuccess() {
  const { worldPack: pack, setWorldPack } = useWorldPack();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!pack.title) {
      navigate("/packer/world");
    }
  }, []);

  function resetPack() {
    setWorldPack(newWorldPack());
    navigate("/packer/world");
  }

  return (
    <main className="container justify-center align-center full-width full-height">
      <Result
        style={{ width: "50%" }}
        status="success"
        title="The package is ready to be packed"
        subTitle="You can now download you world pack."
        extra={[
          <Button
            type="primary"
            key="download"
            icon={<DownloadOutlined />}
            onClick={async () => {
              setLoading(true);
              await createWorldPackZip(pack).then(() => setLoading(false));
            }}
            loading={loading}
          >
            Pack
          </Button>,
          <Button key="return" onClick={() => navigate("/packer/world")}>
            Return
          </Button>,
          <Popconfirm
            placement="bottomRight"
            title="Are you sure?"
            description="This action will reset the previous pack"
            onConfirm={() => resetPack()}
            okText="Yes"
            cancelText="No"
            key="reset"
          >
            <Button type="dashed" danger>
              New pack
            </Button>
          </Popconfirm>,
        ]}
      ></Result>
    </main>
  );
}
